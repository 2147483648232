
// Services
import { getApplicationName } from 'services/utils';

// CSS
import styles from './Application.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- APPLICATION ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Application({
    applicationName,
    type,
    containerProps,
} : {
    applicationName: string,
    type: 'chip' | 'pellet',
    containerProps?: React.CSSProperties,
}) {

    const getClass = () => {

        switch (applicationName) {

            case 'zerobarrier':
                return styles.zb;

            case 'happinco':
                return styles.hp;

            case 'mealsAndBenefits':
                return styles.mb;
        }
    };

    const getAcronym = () => {

        switch (applicationName) {

            case 'zerobarrier':
                return 'ZB';

            case 'happinco':
                return 'HP';

            case 'mealsAndBenefits':
                return 'RB';
        }
    };

    switch (type) {

        case 'chip':
            return (
                <div
                    className={`chip ${getClass()}`}
                    style={containerProps}
                >
                    {getApplicationName(applicationName)}
                </div>
            );

        case 'pellet':
            return (
                <div
                    className={`${styles.pellet} ${getClass()}`}
                    style={containerProps}
                >
                    {getAcronym()}
                </div>
            );
    }
}
