import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';

// Apollo
import { useMutation } from '@apollo/client';
import { UPDATE_GUIDE_CATEGORY } from './gql-update-guide-category';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Button, CircularProgress, Fab, Tooltip } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';

// Services
import { getApplicationName, getLocales } from 'services/utils';
import { getErrorExtensions } from 'services/utils';
import { IGuideCategory } from 'services/interfaces';

// Components
import ContentLayout from 'components/content-layout';
import Title from 'components/title';

// Views
import GuideCategoryForm from '../guide-category-form';
import { IState } from '../create-guide-category';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE GUIDE CATEGORY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UpdateGuideCategory() {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [updateGuideCategory, { loading, error, data }] = useMutation(UPDATE_GUIDE_CATEGORY);

    const location = useLocation();
    const guideCategory : IGuideCategory | undefined = location.state?.guideCategory;

    const defaultTranslations = getLocales().map(locale => {
        let t = guideCategory?.translations?.find(t => t.locale === locale.value);
        if (t) {
            return ({ id: t.id, locale: t.locale, name: t.name, description: t.description });
        }
        return ({ locale: locale.value, name: '', description: '' });
    });

    const [state, setState] = useState<IState>({
        actionType: 'UPDATE',
        translations: defaultTranslations,
        application: guideCategory?.application ? ({ value: guideCategory.application.id, label: getApplicationName(guideCategory.application.name) }) : null,
        accessibleOffline: guideCategory?.accessibleOffline || false,
        parentCategory: guideCategory?.parentCategory ? ({ value: guideCategory.parentCategory.id, label: guideCategory.parentCategory.name }) : null,
    });

    const submitDisabled = state.translations.every(t => t.name === '');

    function onCreateCategory() {
        updateGuideCategory({
            variables: {
                id: guideCategory?.id,
                accessibleOffline: state.accessibleOffline,
                application: state.application ? { connect: state.application.value } : ((state.application == null && guideCategory?.application) ? { disconnect: true } : null),
                translations: {
                    create: state.translations.filter(t => t.id == null && t.name !== ''),
                    update: state.translations.filter(t => t.id != null && t.name !== ''),
                    delete: state.translations.filter(t => t.id != null && t.name === '').map(t => t.id),
                },
                parentCategory: state.parentCategory ? { connect: state.parentCategory.value } : ((state.parentCategory == null && guideCategory?.parentCategory) ? { disconnect: true } : null),
            },
        });
    }

    useEffect(() => {
        if (data?.updateGuideCategory) {
            enqueueSnackbar(t('updated_successfully'), { variant: 'success' });
            navigate('/guide-categories/' + data.updateGuideCategory.id);
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <ContentLayout>
            <Grid
                container justifyContent="center"
                style={{ position: 'relative', marginBottom: 24 }}
            >
                <Tooltip
                    title={t('back')}
                    placement="bottom"
                >
                    <Fab
                        component={Link}
                        to={'/guide-categories/' + guideCategory?.id}
                        style={{ position: 'absolute', left: 0, top: -10 }}
                    >
                        <BackIcon />
                    </Fab>
                </Tooltip>
                <Title titleProps={{ fontSize: 18 }}>
                    {t('edit_category')}
                </Title>
            </Grid>
            <GuideCategoryForm
                state={state}
                setState={setState}
                parentCategory={guideCategory?.parentCategory ? ({ value: guideCategory.parentCategory.id, label: guideCategory.parentCategory.name }) : null}
            />
            <Grid
                container justifyContent="center"
                style={{ marginTop: 24 }}
            >
                <Button
                    onClick={onCreateCategory}
                    disabled={loading || submitDisabled}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                >
                    {t('save')}
                </Button>
            </Grid>
        </ContentLayout>
    );
}
