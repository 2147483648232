
// MUI
import { SvgIconProps } from '@mui/material';
import {
    Category as CategoryIcon, ArrowBack as ArrowBackIcon,
    Handyman as HandymanIcon, Psychology as PsychologyIcon,
} from '@mui/icons-material';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GUIDE CATEGORY -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const GuideCategoryIcon = (props: SvgIconProps) => {
    return <CategoryIcon {...props} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- TECHNICAL GUIDE ICON ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const TechnicalGuideIcon = (props: SvgIconProps) => {
    return <HandymanIcon {...props} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ PEDAGOGICAL GUIDE ICON ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const PedagogicalGuideIcon = (props: SvgIconProps) => {
    return <PsychologyIcon {...props} />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ARROW BACK ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const BackIcon = () => {
    return <ArrowBackIcon />;
};
