import { useTranslation } from 'react-i18next';

// Services
import { IModelTranslation, ISelectOptionWithApp } from 'services/interfaces';
import { getApplicationName } from 'services/utils';

// Components
import TranslationsForm from 'components/translations-form';

// Selects
import SelectGuideCategory from 'selects/select-guide-category';
import SelectApplication from 'selects/select-application';
import SelectRole from 'selects/select-role';
import SelectGuideType from 'selects/select-guide-type';

// Views
import { IState } from '../create-user-guide/index';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- USER GUIDE FORM ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UserGuideForm({
    state,
    setState,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
}) {

    const { t } = useTranslation();

    const onChangeGuideCategory = (guideCategory: ISelectOptionWithApp | null) => {
        setState(prevState => ({
            ...prevState,
            guideCategory,
            application: guideCategory?.application ? ({ value: guideCategory.application.id, label: getApplicationName(guideCategory.application.name) }) : null,
        }));
    };

    const onChangeTranslations = (modelTranslations: IModelTranslation[]) => {
        setState(prevState => ({
            ...prevState,
            translations: prevState.translations.map(t => {
                let modelTrans = modelTranslations.find(mt => mt.locale === t.locale);
                return ({ ...t, title: modelTrans?.name || '', description: modelTrans?.description });
            }),
        }));
    };

    return (
        <>
            {/* -------------------- TRANSLATIONS -------------------- */}
            <TranslationsForm
                translations={state.translations.map(t => ({ locale: t.locale, name: t.title, description: t.description }))}
                setTranslations={(translations: any) => onChangeTranslations(translations)}
            />
            {/* -------------------- GUIDE TYPE -------------------- */}
            <SelectGuideType
                value={state.guideType}
                onChange={guideType => setState(prevState => ({ ...prevState, guideType }))}
                containerProps={{ marginTop: 24 }}
            />
            {/* -------------------- GUIDE CATEGORY -------------------- */}
            <SelectGuideCategory
                title={t('category')}
                value={state.guideCategory}
                onChange={guideCategory => onChangeGuideCategory(guideCategory)}
                isDisabled={state.actionType === 'CREATE'}
                containerProps={{ marginTop: 24 }}
            />
            {/* -------------------- APPLICATION -------------------- */}
            <SelectApplication
                value={state.application}
                onChange={application => setState(prevState => ({ ...prevState, application }))}
                isDisabled={!!(state.guideCategory?.application)}
                containerProps={{ marginTop: 24 }}
            />
            {/* -------------------- ROLE -------------------- */}
            <SelectRole
                title={t('roles')}
                value={state.roles}
                onChange={roles => setState(prevState => ({ ...prevState, roles }))}
                isMulti
                containerProps={{ marginTop: 24 }}
            />
        </>
    );
}
