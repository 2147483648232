import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogContent, DialogActions, CircularProgress, Button } from '@mui/material';
import { WarningRounded as WarningIcon } from '@mui/icons-material'; 

// Apollo
import { useMutation } from '@apollo/client';
import { DELETE_GUIDE_CATEGORY } from './gql-delete-guide-category';

// Services
import { getErrorExtensions } from 'services/utils';
import { IGuideCategory } from 'services/interfaces';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ DELETE GUIDE CATEGORY ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function DeleteGuideCategory({ guideCategory, onClose } : { guideCategory: IGuideCategory, onClose: VoidFunction }) {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteGuideCategory, { loading, error, data }] = useMutation(DELETE_GUIDE_CATEGORY);

    useEffect(() => {
        if (data?.deleteGuideCategory) {

            enqueueSnackbar(t('deleted_successfully'), { variant: 'success' });

            if (guideCategory?.parentCategory) {
                onClose();
                navigate('/guide-categories/' + guideCategory.parentCategory.id);
            }
            else {
                navigate('/guide-categories');
            }
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            open
            onClose={loading ? undefined : onClose}
        >
            <DialogContent>
                {t('confirm_delete_category')}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    color="inherit"
                >
                    {t('no')}
                </Button>
                <Button
                    onClick={() => deleteGuideCategory({ variables: { id: guideCategory.id }})}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <WarningIcon fontSize="small" />}
                    color="warning"
                >
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
