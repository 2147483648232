import i18next from 'i18next';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ERROR EXTENSIONS  --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getErrorExtensions = (error: any) => {
    
    let hasErrorExtension = false;
    let message = '';

    for (let err of error.graphQLErrors) {
        let item = '';

        if (err?.extensions?.validation && Object.values(err?.extensions?.validation)?.length > 0) {
            hasErrorExtension = true;

            Object.values(err.extensions.validation).forEach((value: any) => {
                value.forEach((v: any) => item += v);
            });
        }
        message += item;
    }

    if (hasErrorExtension) {
        return message;
    }
    return error?.message;
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LOCALES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getLocales = () => [
    {
        value: 'fr',
        label: i18next.t('locale.fr'),
        notTranslated: 'Français',
    },
    {
        value: 'fr-FALC',
        label: i18next.t('locale.fr-falc'),
        notTranslated: 'Français - FALC',
    },
    {
        value: 'en',
        label: i18next.t('locale.en'),
        notTranslated: 'English',
    },
    {
        value: 'de',
        label: i18next.t('locale.de'),
        notTranslated: 'Deutsch',
    },
    {
        value: 'sl',
        label: i18next.t('locale.sl'),
        notTranslated: 'Slovenščina',
    },
];

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CAPITALIZE FIRST LETTER ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const capitalizeFirstLetter = (value: string | undefined) => {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
};

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GET APPLICATION NAME ------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getApplicationName = (name: string) => {

    switch (name) {
        
        case 'zerobarrier':
            return 'Zero Barrier';

        case 'happinco':
            return 'Happ\'In Co';

        case 'mealsAndBenefits':
            return i18next.t('meals_and_benefits');

        default:
            return name;
    }
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- GET ROLE LABEL ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const getRoleLabel = (roleId: string | undefined) => {

    switch (roleId) {

        case '1':
            return i18next.t('super_admin');

        case '2':
            return i18next.t('admin');

        case '3':
            return i18next.t('manager');

        case '4':
            return i18next.t('trainer');

        case '5':
            return i18next.t('learner');

        default:
            return roleId || '';
    }
};
