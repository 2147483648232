import React from 'react';

// CSS
import './animated-badge.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- ANIMATED BADGE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const AnimatedBadge : React.FC<{
    children: React.ReactNode,
    disabled?: boolean,
}> = ({
          children,
          disabled = false,
      }) => {
    return (
        <div className="animated-badge-container">
            {children}
            {!disabled && (
                <div className="animated-badge">
                    <div className="animated-badge-pellet" />
                </div>
            )}
        </div>
    );
};

export default AnimatedBadge;
