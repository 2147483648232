import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { CircularProgress, Grid } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import { UPDATE_USER_GUIDE_TRANSLATIONS } from '../../update-user-guide/gql-update-user-guide';

// Services
import { IUserGuide } from 'services/interfaces';
import { getErrorExtensions } from 'services/utils';

// Components
import { RichTextEditor, ReadOnlyEditor } from 'components/rich-text-editor';

// Views
import { IMenuContext, MenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------ USER GUIDE TRANSLATIONS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UserGuideTranslations({ userGuide, refetch } : { userGuide: IUserGuide | undefined, refetch: any }) {

    const { t } = useTranslation();
    const { adminMode } = useContext(MenuContext) as IMenuContext;
    const { enqueueSnackbar } = useSnackbar();
    const [updateTranslations, { loading, error, data }] = useMutation(UPDATE_USER_GUIDE_TRANSLATIONS);

    const [state, setState] = useState({
        selectedLocale: userGuide?.translations?.some(t => t.locale === 'fr') ? 'fr' : 'en',
        translations: userGuide?.translations?.map(t => ({ id: t.id, locale: t.locale, title: t.title, content: t.content })) || [],
    });

    const onChangeContent = (value: string) => {
        let translations = [...state.translations];
        let newTrans = translations.find(t => t.locale === state.selectedLocale);
        if (newTrans) {
            newTrans.content = value;
        }
        setState(prevState => ({ ...prevState, translations }));
    };

    const onUpdateTranslations = () => {
        updateTranslations({
            variables: {
                id: userGuide?.id,
                translations: { update: state.translations },
            },
        });
    };

    useEffect(() => {
        if (data?.updateUserGuide) {
            enqueueSnackbar(t('saved_successfully'), { variant: 'success' });
            refetch();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line
    }, [data, error]);

    return (
        <div className="UserGuide-translations">
            {loading && (
                <div className="UserGuide-backdrop">
                    <CircularProgress style={{ color: 'white' }} />
                </div>
            )}
            {adminMode ? (
                <>
                    <Grid
                        marginTop={3}
                        container
                        alignItems="flex-start"
                        style={{ padding: '12px 24px 0px 24px', backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                    >
                        {state.translations.map(t => (
                            <Grid
                                key={t.id}
                                item xs="auto"
                            >
                                <div
                                    onClick={() => setState(prevState => ({ ...prevState, selectedLocale: t.locale }))}
                                    className={`UserGuide-tab ${state.selectedLocale === t.locale ? 'UserGuide-tabSelected' : 'UserGuide-tabUnselected'}`}
                                >
                                    {t.locale.includes('-') ? t.locale.split('-')[1] : t.locale}
                                </div>
                            </Grid>
                        ))}
                        <Grid
                            item xs
                            container justifyContent="flex-end"
                        >
                            <button
                                onClick={onUpdateTranslations}
                                className="UserGuide-saveButton"
                            >
                                {t('save')}
                            </button>
                        </Grid>
                    </Grid>
                    <div className="UserGuide-translationsContainer">
                        <RichTextEditor
                            value={state.translations.find(t => t.locale === state.selectedLocale)?.content}
                            onChange={value => onChangeContent(value)}
                        />
                    </div>
                </>
            ) : (
                <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <ReadOnlyEditor
                        value={userGuide?.content || ''}
                    />
                </div>
            )}
        </div>
    );
}
