import { gql } from '@apollo/client';

export const CREATE_USER_GUIDE = gql`
    mutation CreateUserGuide(
        $guideType: GuideType
        $translations: UpdateUserGuideTranslationsHasMany
        $guideCategory: ConnectOrDisconnectGuideCategoryBelongsTo
        $application: ConnectOrDisconnectApplicationBelongsTo
        $roles: SyncRolesBelongsToMany
    ) {
        createUserGuide(input: {
            guideType: $guideType
            translations: $translations
            guideCategory: $guideCategory
            application: $application
            roles: $roles
        }) {
            id
        }
    }
`;
