import { useTranslation } from 'react-i18next';

// MUI
import { FormControlLabel, Switch } from '@mui/material';

// Services
import { ISelectOption } from 'services/interfaces';

// Components
import TranslationsForm from 'components/translations-form';

// Selects
import SelectGuideCategory from 'selects/select-guide-category';
import SelectApplication from 'selects/select-application';

// Views
import { IState } from '../create-guide-category';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- GUIDE CATEGORY FORM ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function GuideCategoryForm({
    state,
    setState,
    parentCategory,
} : {
    state: IState,
    setState: React.Dispatch<React.SetStateAction<IState>>,
    parentCategory?: ISelectOption | null,
}) {

    const { t } = useTranslation();

    return (
        <>
            {/* -------------------- TRANSLATIONS -------------------- */}
            <TranslationsForm
                translations={state.translations}
                setTranslations={translations => setState(prevState => ({ ...prevState, translations }))}
            />
            {/* -------------------- PARENT CATEGORY -------------------- */}
            {parentCategory && (
                <SelectGuideCategory
                    title={t('is_part_of')}
                    value={state.parentCategory}
                    onChange={parentCategory => setState(prevState => ({ ...prevState, parentCategory }))}
                    isDisabled={state.actionType === 'CREATE'}
                    containerProps={{ marginTop: 24 }}
                />
            )}
            {/* -------------------- APPLICATION -------------------- */}
            <SelectApplication
                value={state.application}
                onChange={application => setState(prevState => ({ ...prevState, application }))}
                containerProps={{ marginTop: 24 }}
            />
            {/* -------------------- ACCESSIBLE OFFLINE -------------------- */}
            <FormControlLabel
                label={t('accessible_offline')}
                style={{ marginTop: 24 }}
                control={
                    <Switch
                        checked={state.accessibleOffline}
                        onChange={event => setState(prevState => ({ ...prevState, accessibleOffline: event.target.checked }))}
                    />
                }
            />
        </>
    );
}
