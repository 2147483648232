import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation Login(
        $login: String
        $password: String!
    ) {
        login(
            login: $login
            password: $password
        ) {
            token
            user {
                id
                fullName
                lastName
                firstName
                email
                hasVerifiedEmail
                phoneNumber
                hasVerifiedPhoneNumber
                phoneNumberPrefix
                birthdate
                gender
                photoUrl
                locale
                permissions
                role {
                    id
                    name
                }
            }
        }
    }
`;
