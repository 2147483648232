
// CSS
import './accessible-offline.css';

// MUI
import { NoAccounts as AccessibleOfflineIcon } from '@mui/icons-material';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- ACCESSIBLE OFFLINE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AccessibleOffline({ containerProps } : { containerProps?: React.CSSProperties }) {
    return (
        <div
            className="AccessibleOffline"
            style={containerProps}
        >
            <AccessibleOfflineIcon />
        </div>
    );
}
