import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Services
import { EGuideType } from 'services/enums';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SELECT GUIDE TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectGuideType({
    value,
    onChange,
    isDisabled = false,
    containerProps,
} : {
    value: any,
    onChange: (value: any) => void,
    isDisabled?: boolean,
    containerProps?: React.CSSProperties,
}) {

    const { t } = useTranslation();

    return (
        <div style={containerProps}>
            <Title>
                {t('guide_type')}
            </Title>
            <Select
                placeholder={t('guide_types')}
                value={value}
                onChange={onChange}
                options={[{ value: EGuideType.TECHNICAL, label: t('technical') }, { value: EGuideType.PEDAGOGICAL, label: t('pedagogical') }]}
                menuPlacement="auto"
                isDisabled={isDisabled}
                styles={{ control: base => ({...base, marginTop: 4 }) }}
            />
        </div>
    );
}
