import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './side-menu.css';

// MUI
import { Grid, Divider, Drawer, ListItemButton, IconButton } from '@mui/material';
import { Logout as LogoutIcon, Close as CloseIcon } from '@mui/icons-material';

// Permissions
import { isSuperAdmin } from 'services/permissions';

// Services
import { useMediaQueries } from 'services/hooks';
import PackageJSON from '../../../package.json';

// Views
import AdminMode from './admin-mode';
import ToggleGuideType from './toggle-guide-type';
import ToggleApplication from './toggle-application';
import Logout from 'views/logout';
import { MenuContext, IMenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SIDE MENU ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SideMenu({ open, onClose } : { open: boolean, onClose: VoidFunction }) {

    const { mediaSM, mediaLG } = useMediaQueries();
    const [openLogout, setOpenLogout] = useState(false);

    const onOpenLogout = () => {
        if (onClose) {
            onClose();
        }
        setOpenLogout(true);
    };

    return (
        <>
            {mediaLG ? (
                <div className="SideMenu">
                    <MenuList
                        onOpenLogout={onOpenLogout}
                    />
                </div>
            ) : (
                <Drawer
                    open={open}
                    onClose={onClose}
                    anchor="left"
                    PaperProps={{ style: { width: mediaSM ? 220 : '100%' }}}
                    ModalProps={{ style: { zIndex: 1400 } }}
                >
                    <Grid
                        container
                        justifyContent="end"
                        style={{ padding: '12px 8px' }}
                    >
                        <IconButton
                            onClick={onClose}
                            color="inherit"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Divider />
                    <MenuList
                        onOpenLogout={onOpenLogout}
                    />
                </Drawer>
            )}
            {/* -------------------- LOGOUT -------------------- */}
            {openLogout && (
                <Logout
                    onClose={() => setOpenLogout(false)}
                />
            )}
        </>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MENU LIST ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function MenuList({ onOpenLogout } : { onOpenLogout: VoidFunction }) {

    const { t } = useTranslation();
    const { authenticated } = useContext(MenuContext) as IMenuContext;

    return (
        <div className="SideMenu-menuList">
            {(authenticated && isSuperAdmin()) && (
                <AdminMode />
            )}
            <ToggleGuideType />
            <Divider />
            <ToggleApplication />
            <Divider />
            {/* -------------------- LOGOUT -------------------- */}
            {authenticated && (
                <ListItemButton
                    onClick={onOpenLogout}
                    style={{ padding: 16 }}
                >
                    <LogoutIcon />
                    <span style={{ marginLeft: 8 }}>
                        {t('logout')}
                    </span>
                </ListItemButton>
            )}
            {/* -------------------- VERSION -------------------- */}
            <div className="SideMenu-version">
                v{PackageJSON.version}
            </div>
        </div>
    );
}
