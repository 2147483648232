import { gql } from '@apollo/client';

export const CREATE_GUIDE_CATEGORY = gql`
    mutation CreateGuideCategory(
        $position: Int
        $accessibleOffline: Boolean
        $translations: UpdateTranslationsHasMany
        $parentCategory: ConnectOrDisconnectGuideCategoryBelongsTo
        $application: ConnectOrDisconnectApplicationBelongsTo
    ) {
        createGuideCategory(input: {
            position: $position
            accessibleOffline: $accessibleOffline
            translations: $translations
            parentCategory: $parentCategory
            application: $application
        }) {
            id
        }
    }
`;
