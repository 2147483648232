import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import i18next from 'i18next';

// Permissions
import { isSuperAdmin } from 'services/permissions';

// Services
import { getStoredToken } from 'services/storage';
import { EGuideType } from 'services/enums';

// Components
import AppBar from 'components/app-bar';
import SideMenu from 'components/side-menu';

/* * *
* Views
*/

import Login from 'views/login';
import LoginWithCode from 'views/login-with-code';

import GuideCategories from 'views/guide-categories';
import GuideCategory from 'views/guide-categories/guide-category';
import CreateGuideCategory from 'views/guide-categories/create-guide-category';
import UpdateGuideCategory from 'views/guide-categories/update-guide-category';

import UserGuide from 'views/user-guides/user-guide';
import CreateUserGuide from 'views/user-guides/create-user-guide';
import UpdateUserGuide from 'views/user-guides/update-user-guide';

/* * *
* Menu Context
*/

interface IState {
    authenticated: boolean;
    adminMode: boolean;
    guideType: EGuideType | null;
    applicationId: string;
    openMenu: boolean;
}

export interface IMenuContext {
    authenticated: boolean;
    adminMode: boolean;
    guideType: EGuideType | null;
    applicationId: string;
    openMenu: boolean;
    setContext: React.Dispatch<React.SetStateAction<IState>>;
}

export const MenuContext = createContext<IMenuContext | null>(null);

/* * *
* Require Authentification
*/

const RequireAuth = ({ children } : { children: JSX.Element }) => {
    
    const storedToken = getStoredToken();
    const location = useLocation();

    if (storedToken && isSuperAdmin()) {
        return children;
    }
    return <Navigate to="/" state={{ from: location }} replace />;
};

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- APP ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function App() {

    const searchParams = new URLSearchParams(document.location.search);

    const [state, setState] = useState<IState>({
        authenticated: !! (localStorage.getItem('token')),
        adminMode: false,
        guideType: null,
        applicationId: searchParams.get('applicationId') || '',
        openMenu: false,
    });

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('locale') || 'fr');
    }, []);

    return (
        <MenuContext.Provider
            value={{
                authenticated: state.authenticated,
                adminMode: state.adminMode,
                guideType: state.guideType,
                applicationId: state.applicationId,
                openMenu: state.openMenu,
                setContext: setState,
            }}
        >
            <AppBar />
            <SideMenu
                open={state.openMenu}
                onClose={() => setState(prevState => ({ ...prevState, openMenu: false }))}
            />
            <div className="App-content">
                <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="login-with-code" element={<LoginWithCode />} />
                    <Route path="guide-categories">
                        <Route index element={<GuideCategories />} />
                        <Route path="create" element={<RequireAuth><CreateGuideCategory /></RequireAuth>} />
                        <Route path=":guideCategoryId">
                            <Route index element={<GuideCategory />} />
                            <Route path="update" element={<RequireAuth><UpdateGuideCategory /></RequireAuth>} />
                            <Route path="user-guides/create" element={<RequireAuth><CreateUserGuide /></RequireAuth>} />
                            <Route path="user-guides/:userGuideId">
                                <Route index element={<UserGuide />} />
                                <Route path="update" element={<RequireAuth><UpdateUserGuide /></RequireAuth>} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="*" element={<Navigate to="/guide-categories" />} />
                </Routes>
            </div>
        </MenuContext.Provider>
    );
}
