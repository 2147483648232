import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

// CSS
import './app-bar.css';

// MUI
import { Grid, ListItemButton, Tooltip, IconButton, Avatar, Fab, Menu, MenuItem } from '@mui/material';
import { Language as LocaleIcon, Login as LoginIcon, Menu as MenuIcon, Person as PersonIcon } from '@mui/icons-material';

// Services
import { getStoredUser } from 'services/storage';
import { useMediaQueries } from 'services/hooks';
import { getLocales } from 'services/utils';
import client, { API_URL } from 'services/apollo-client';

// Assets
import DiscoviaCircle from 'assets/images/discovia-circle.png';

// Views
import { MenuContext, IMenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- APP BAR ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AppBar() {

    const storedUser = getStoredUser();
    const { t } = useTranslation();
    const { mediaSM, mediaLG } = useMediaQueries();
    const { authenticated, setContext } = useContext(MenuContext) as IMenuContext;

    return (
        <div className="AppBar">
            <Grid
                container
                alignItems="center"
                spacing={1}
            >
                {/* -------------------- MENU ICON -------------------- */}
                {!mediaLG && (
                    <Grid item xs="auto">
                        <IconButton onClick={() => setContext(prevContext => ({ ...prevContext, openMenu: !prevContext.openMenu }))}>
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                )}
                {/* -------------------- LOGO -------------------- */}
                <Grid item xs sm="auto">
                    <ListItemButton
                        component={Link}
                        to="/guide-cateogries"
                        className="AppBar-listItemButton"
                    >
                        <img
                            alt="Cercle du logo Discovia"
                            src={DiscoviaCircle}
                            width={42}
                            height={42}
                            loading="eager"
                        />
                        <span className="AppBar-logoTitle">
                            {t('user_guide.1')}
                        </span>
                    </ListItemButton>
                </Grid>
                {/* -------------------- FILL DIV -------------------- */}
                {mediaSM && (
                    <Grid
                        item xs
                    />
                )}
                {/* -------------------- CONNEXION -------------------- */}
                <Grid item xs="auto">
                    <Tooltip
                        title={authenticated ? storedUser?.fullName : t('login')}
                        placement="bottom"
                    >
                        {authenticated ? (
                            <Avatar src={storedUser?.photoUrl ? (API_URL + storedUser.photoUrl) : undefined}>
                                <PersonIcon />
                            </Avatar>
                        ) : (
                            <Fab
                                component={Link}
                                to="/login"
                            >
                                <LoginIcon />
                            </Fab>
                        )}
                    </Tooltip>
                </Grid>
                {/* -------------------- LOCALE -------------------- */}
                <Grid item xs="auto">
                    <LocaleHandler />
                </Grid>
            </Grid>
        </div>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- LOCALE HANDLER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function LocaleHandler() {

    const locales = getLocales();

    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onChangeLocale = (locale: string) => {
        i18next.changeLanguage(locale);
        localStorage.setItem('locale', locale);
        client.reFetchObservableQueries();
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title={t('language')}>
                <Fab onClick={e => setAnchorEl(e.currentTarget)}>
                    <LocaleIcon />
                </Fab>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ marginTop: 3 }}
                disableScrollLock
            >
                {locales.map(locale => (
                    <MenuItem
                        key={locale.value}
                        onClick={() => onChangeLocale(locale.value)}
                        selected={i18next.language === locale.value}
                    >
                        {locale.notTranslated}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}