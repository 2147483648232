import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogContent, DialogActions, CircularProgress, Button } from '@mui/material';
import { WarningRounded as WarningIcon } from '@mui/icons-material'; 

// Apollo
import { useMutation } from '@apollo/client';
import { DELETE_USER_GUIDE } from './gql-delete-user-guide';

// Services
import { getErrorExtensions } from 'services/utils';
import { IUserGuide } from 'services/interfaces';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- DELETE USER GUIDE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function DeleteUserGuide({ userGuide, onClose } : { userGuide: IUserGuide, onClose: VoidFunction }) {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { guideCategoryId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteUserGuide, { loading, error, data }] = useMutation(DELETE_USER_GUIDE);

    useEffect(() => {
        if (data?.deleteUserGuide) {
            enqueueSnackbar(t('deleted_successfully'), { variant: 'success' });
            navigate('/guide-categories/' + guideCategoryId);
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            open
            onClose={loading ? undefined : onClose}
        >
            <DialogContent>
                {t('confirm_delete_user_guide')}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    color="inherit"
                >
                    {t('no')}
                </Button>
                <Button
                    onClick={() => deleteUserGuide({ variables: { id: userGuide.id }})}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <WarningIcon fontSize="small" />}
                    color="warning"
                >
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
