import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import i18next from 'i18next';

// Services
import { clearStorage, getStoredToken } from 'services/storage';

/* * *
* API URL
*/

export var API_URL : string;

switch (window.origin) {

    case 'http://localhost:3000':
    case 'http://localhost:3001':
        API_URL = 'http://127.0.0.1:8000/';
        break;

    default:
        API_URL = window.origin + '/';
        break;
}

/* * *
* APOLLO UPLOAD CLIENT
* Enable to send file on mutation
*/

const uploadLink = createUploadLink({
    uri: API_URL + 'graphql',
});

/* * *
* APOLLO AUTH LINK
* Handle request headers
*/

const authLink = setContext((_, { headers }) => {

    const token = getStoredToken();

    return {
        headers: {
            ...headers,
            'authorization': token ? 'Bearer ' + token : '',
            'x-localization': i18next.language,
            'x-application': 'userGuide',
        },
    };
});

/* * *
* APOLLO ERROR LINK
* Handle logout when user is unauthenticated
*/

const errorLink = onError(({ graphQLErrors, networkError }) => {

    if (graphQLErrors) {

        for (let err of graphQLErrors) {

            console.error(`[GraphQL error]: ${err.message}`);
            
            if (err.message !== '' && err.message.includes('Unauthenticated')) {
                clearStorage();
                client.clearStore();
                window.location.reload();
            }
        }
    }
    
    if (networkError) {
        console.error(`[Network error]: ${networkError.message}`);
    }
});

// --------------------------------------------------------------------------------- \\
// ----------------------------------- CLIENT -------------------------------------- \\
// --------------------------------------------------------------------------------- \\

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authLink, errorLink, uploadLink]),
});

export default client;
