import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { arrayMoveImmutable } from 'array-move';

// MUI
import { Grid, Fab, Tooltip, ListItemButton } from '@mui/material';
import { Add as AddIcon, MoveDown as SortIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { REORDER_GUIDE_CATEGORIES } from '../../gql-guide-categories';

// Services
import { IGuideCategory } from 'services/interfaces';
import { capitalizeFirstLetter } from 'services/utils';

// Icons
import { GuideCategoryIcon } from 'icons';

// Components
import Title from 'components/title';
import NoResult from 'components/no-result';
import AnimatedBadge from 'components/animated-badge';
import Application from 'components/application';
import { SortableItem, SortableList } from 'components/sortable-list';

// Views
import { IMenuContext, MenuContext } from 'app';

interface IState {
    childrenCategories: IGuideCategory[];
    sortingChildren: boolean;
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- CHILDREN CATEGORIES ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ChildrenCategories({ guideCategory, refetch } : { guideCategory: IGuideCategory | undefined, refetch: any }) {

    const { t } = useTranslation();
    const { adminMode } = useContext(MenuContext) as IMenuContext;
    const [reorderGuideCategories, { data }] = useMutation(REORDER_GUIDE_CATEGORIES);

    const [state, setState] = useState<IState>({
        childrenCategories: guideCategory?.childrenCategories || [],
        sortingChildren: false,
    });

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        document.body.style.cursor = 'default';
        let sortedCategories = arrayMoveImmutable(state.childrenCategories, oldIndex, newIndex);
        setState(prevState => ({ ...prevState, childrenCategories: sortedCategories.map((b, index) => ({ ...b, position: index + 1 })) }));
        reorderGuideCategories({ variables: { guideCategories: sortedCategories.map((c, index) => ({ id: c.id, position: index + 1 })) } });
    };

    useEffect(() => {
        if (guideCategory) {
            setState(prevState => ({ ...prevState, childrenCategories: guideCategory.childrenCategories }));
        }
    }, [guideCategory]);

    useEffect(() => {
        if (data?.reorderGuideCategories) {
            refetch();
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <div className="GuideCategory-borderCard">
            <div style={{ padding: 16 }}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs>
                        <Title>{t('subcategories')}</Title>
                    </Grid>
                    {adminMode && (
                        <>
                            <Grid item xs="auto">
                                <Tooltip title={t('add_subcategory')}>
                                    <Fab
                                        component={Link}
                                        to="/guide-categories/create"
                                        state={{ parentCategory: guideCategory }}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                            {state.childrenCategories.length > 1 && (
                                <Grid item xs="auto">
                                    <AnimatedBadge disabled={!state.sortingChildren}>
                                        <Tooltip title={t('reorder')}>
                                            <Fab
                                                onClick={() => setState(prevState => ({ ...prevState, sortingChildren: !prevState.sortingChildren }))}
                                                color={state.sortingChildren ? 'success' : 'default'}
                                            >
                                                <SortIcon />
                                            </Fab>
                                        </Tooltip>
                                    </AnimatedBadge>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </div>
            {state.childrenCategories.length > 0 ? (
                <SortableList
                    onSortStart={() => document.body.style.cursor = 'grabbing'}
                    onSortEnd={onSortEnd}
                    axis="xy"
                >
                    <div>
                        {state.childrenCategories.map((child, index) => (
                            <SortableItem
                                key={child.id}
                                index={index}
                                disabled={!state.sortingChildren}
                            >
                                <div
                                    className="ListItemCard"
                                    style={{ cursor: state.sortingChildren ? 'grab' : undefined }}
                                >
                                    <ListItemButton
                                        component={Link}
                                        to={'/guide-categories/' + child.id}
                                        disabled={state.sortingChildren}
                                    >
                                        <div className="ListItemCard-label">
                                            <GuideCategoryIcon />
                                            <span style={{ marginLeft: 8 }}>
                                                {capitalizeFirstLetter(child.name)}
                                            </span>
                                        </div>
                                        {child?.application && (
                                            <Application
                                                applicationName={child.application.name}
                                                type="pellet"
                                            />
                                        )}
                                    </ListItemButton>
                                </div>
                            </SortableItem>
                        ))}
                    </div>
                </SortableList>
            ) : (
                <NoResult />
            )}
        </div>
    );
}
