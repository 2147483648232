import { useTranslation } from 'react-i18next';

// Services
import { EGuideType } from 'services/enums';

// CSS
import styles from './GuideType.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- GUIDE TYPE ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function GuideType({
    guideType,
    containerProps,
} : {
    guideType: EGuideType,
    containerProps?: React.CSSProperties,
}) {

    const { t } = useTranslation();

    const getClass = () => {

        switch (guideType) {

            case EGuideType.TECHNICAL:
                return styles.technical;

            case EGuideType.PEDAGOGICAL:
                return styles.pedagogical;
        }
    };

    return (
        <div
            className={`chip ${getClass()}`}
            style={containerProps}
        >
            {t(guideType.toLowerCase())}
        </div>
    );
}
