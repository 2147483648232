
// MUI
import { CircularProgress } from '@mui/material';

// CSS
import './spinner.css';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- SPINNER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Spinner = () => {
    return (
        <div className="spinner">
            <CircularProgress color="inherit" size={40} />
        </div>
    );
};

export default Spinner;
