import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { arrayMoveImmutable } from 'array-move';

// MUI
import { Grid, Fab, Tooltip, ListItemButton } from '@mui/material';
import { Add as AddIcon, MoveDown as SortIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { REORDER_USER_GUIDES } from '../../update-guide-category/gql-update-guide-category';

// Services
import { IGuideCategory, IUserGuide } from 'services/interfaces';
import { EGuideType } from 'services/enums';

// Icons
import { PedagogicalGuideIcon, TechnicalGuideIcon } from 'icons';

// Components
import Title from 'components/title';
import NoResult from 'components/no-result';
import AnimatedBadge from 'components/animated-badge';
import Application from 'components/application';
import { SortableItem, SortableList } from 'components/sortable-list';

// Views
import { IMenuContext, MenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ USER GUIDES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IState {
    userGuides: IUserGuide[];
    sorting: boolean;
}

export default function UserGuides({ guideCategory, refetch } : { guideCategory: IGuideCategory | undefined, refetch: any }) {

    const { t } = useTranslation();
    const { adminMode, applicationId, guideType } = useContext(MenuContext) as IMenuContext;
    const [reorderUserGuides, { data }] = useMutation(REORDER_USER_GUIDES);

    const getInitialUserGuides = () => {
        if (guideCategory?.userGuides) {
            if (guideType) {
                return guideCategory.userGuides.filter(ug => ug.guideType === guideType);
            }
            return guideCategory.userGuides;
        }
        return [];
    };

    const [state, setState] = useState<IState>({
        userGuides: getInitialUserGuides(),
        sorting: false,
    });

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        
        document.body.style.cursor = 'default';
        let sortedGuides = arrayMoveImmutable(state.userGuides, oldIndex, newIndex);
        setState(prevState => ({ ...prevState, userGuides: sortedGuides.map((b, index) => ({ ...b, position: index + 1 })) }));

        reorderUserGuides({
            variables: {
                id: guideCategory?.id,
                userGuides: { update: sortedGuides.map((c, index) => ({ id: c.id, position: index + 1 })) },
            },
        });
    };

    useEffect(() => {
        if (guideCategory?.userGuides) {
            setState(prevState => ({
                ...prevState,
                userGuides: guideCategory.userGuides.filter(userGuide => {
                    return (
                        (! applicationId || ! userGuide.application || userGuide.application.id === applicationId)
                        && (! guideType || ! userGuide.guideType || userGuide.guideType === guideType)
                    );
                }),
            }));
        }
    }, [guideCategory, applicationId, guideType]);

    useEffect(() => {
        if (data?.updateGuideCategory) {
            refetch();
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <div className="GuideCategory-borderCard">
            <div style={{ padding: 16 }}>
                <Grid
                    spacing={2}
                    container
                    alignItems="flex-start"
                >
                    <Grid item xs>
                        <Title>{t('user_guides')}</Title>
                    </Grid>
                    {adminMode && (
                        <>
                            <Grid item xs="auto">
                                <Tooltip title={t('add_user_guide')}>
                                    <Fab
                                        component={Link}
                                        to={'/guide-categories/' + guideCategory?.id + '/user-guides/create'}
                                        state={{ guideCategory }}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                            {state.userGuides.length > 1 && (
                                <Grid item xs="auto">
                                    <AnimatedBadge disabled={!state.sorting}>
                                        <Tooltip title={t('reorder')}>
                                            <Fab
                                                onClick={() => setState(prevState => ({ ...prevState, sorting: !prevState.sorting }))}
                                                color={state.sorting ? 'success' : 'default'}
                                            >
                                                <SortIcon />
                                            </Fab>
                                        </Tooltip>
                                    </AnimatedBadge>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </div>
            {state.userGuides.length > 0 ? (
                <SortableList
                    onSortStart={() => document.body.style.cursor = 'grabbing'}
                    onSortEnd={onSortEnd}
                    axis="xy"
                >
                    <div>
                        {state.userGuides.map((userGuide, index) => (
                            <SortableItem
                                key={userGuide.id}
                                index={index}
                                disabled={!state.sorting}
                            >
                                <div
                                    className="ListItemCard"
                                    style={{ cursor: state.sorting ? 'grab' : undefined }}
                                >
                                    <ListItemButton
                                        component={Link}
                                        to={'/guide-categories/' + guideCategory?.id + '/user-guides/' + userGuide.id}
                                        disabled={state.sorting}
                                    >
                                        <div className="ListItemCard-label">
                                            {userGuide?.guideType === EGuideType.PEDAGOGICAL ? (
                                                <PedagogicalGuideIcon />
                                            ) : (
                                                <TechnicalGuideIcon />
                                            )}
                                            <span>
                                                {userGuide.title}
                                            </span>
                                        </div>
                                        {userGuide?.application && (
                                            <Application
                                                applicationName={userGuide.application.name}
                                                type="pellet"
                                            />
                                        )}
                                    </ListItemButton>
                                </div>
                            </SortableItem>
                        ))}
                    </div>
                </SortableList>
            ) : (
                <NoResult />
            )}
        </div>
    );
}
