import React from 'react';

// MUI
import { Grid } from '@mui/material';

// CSS
import './content-layout.css';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ CONTENT LAYOUT ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ContentLayout({ children, layoutProps } : { children: React.ReactNode, layoutProps?: React.CSSProperties }) {
    return (
        <Grid
            container
            style={{ flex: 1 }}
        >
            <Grid
                item xs={false} sm={1} md={2} lg={3} xl={4}
            />
            <Grid
                item xs={12} sm={10} md={8} lg={6} xl={4}
                container flexDirection="column"
                className="ContentLayout"
                style={layoutProps}
            >
                {children}
            </Grid>
        </Grid>
    );
}
