import { gql } from '@apollo/client';

export const APPLICATIONS = gql`
    query Applications {
        applications {
            id
            name
        }
    }
`;
