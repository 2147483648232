import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Backdrop, CircularProgress } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import { LOGIN_WITH_CODE } from './gql-login-with-code';

// Services
import { setLogin } from 'services/storage';
import { getErrorExtensions } from 'services/utils';
import { clearStorage } from 'services/storage';
import client from 'services/apollo-client';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ LOGIN WITH CODE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function LoginWithCode() {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loginWithCode, { error, data }] = useMutation(LOGIN_WITH_CODE);

    const { search } = useLocation();
    const code = new URLSearchParams(search).get('code');
    const applicationId = new URLSearchParams(search).get('applicationId');

    useEffect(() => {
        clearStorage();
        client.clearStore();
        loginWithCode({ variables: { loginCode: code }});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data?.loginWithCode) {
            setLogin(data.loginWithCode);
            navigate('/guide-categories', { state: { applicationId: applicationId } });
            window.location.reload();
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
            navigate('/guide-categories');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Backdrop
            open
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
