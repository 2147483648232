import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Apollo
import { useQuery } from '@apollo/client';
import { APPLICATIONS } from './gql-select-application';

// Services
import { getApplicationName } from 'services/utils';
import { ISelectOption, IApplication } from 'services/interfaces';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- SELECT APPLICATION ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectApplication({
    value,
    onChange,
    isDisabled = false,
    containerProps,
} : {
    value: ISelectOption | null,
    onChange: (value: ISelectOption | null) => void,
    isDisabled?: boolean,
    containerProps?: React.CSSProperties,
}) {

    const { t } = useTranslation();
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const { loading, data } = useQuery<{ applications: IApplication[] }>(APPLICATIONS);

    useEffect(() => {
        if (data?.applications) {
            setOptions(data.applications.map(a => ({ value: a.id, label: getApplicationName(a.name) })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div style={containerProps}>
            <Title>
                {t('application')}
            </Title>
            <Select
                placeholder={t('common')}
                value={value}
                onChange={onChange}
                options={options}
                menuPlacement="auto"
                isClearable={true}
                isLoading={loading}
                isDisabled={isDisabled}
                styles={{ control: base => ({...base, marginTop: 4 }) }}
            />
        </div>
    );
}
