import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

// CSS
import './rich-text-editor.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

declare global {
    interface Window {
        Quill: any;
    }
}

Quill.register('modules/imageResize', ImageResize);
window.Quill = Quill;

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- RICH TEXT EDITOR ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function RichTextEditor({
    value,
    onChange,
} : {
    value: string | undefined,
    onChange: (value: string) => void,
}) {
    return (  
        <ReactQuill
            theme="snow"
            value={value}
            onChange={(value, delta, source) => source === 'user' ? onChange(value) : undefined}
            modules={{
                toolbar: [
                    [{ 'header': [false, 2, 1] }],
                    ['bold', 'italic', 'underline'],
                    [{ 'align': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                    [{ 'color': [] }, { 'background': [] }],
                    ['code', 'link', 'image', 'video'],
                ],
                imageResize: {
                    parchment: Quill.import('parchment'),
                    modules: ['Resize', 'DisplaySize', 'Toolbar'],
                },
                clipboard: {
                    matchVisual: false,
                },
            }}
        />
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- READ ONLY EDITOR ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export function ReadOnlyEditor({ value } : { value: string }) {
    return (  
        <ReactQuill
            theme="bubble"
            readOnly={true}
            value={value}
        />
    );
}
