import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Apollo
import { useQuery } from '@apollo/client';
import { ROLES } from './gql-select-role';

// Services
import { ISelectOption, IRole } from 'services/interfaces';
import { getRoleLabel } from 'services/utils';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ SELECT ROLE --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectRole({
    value,
    onChange,
    title,
    isMulti = false,
    containerProps,
} : {
    value: any,
    onChange: (value: any) => void,
    title?: string,
    isMulti?: boolean,
    containerProps?: React.CSSProperties,
}) {

    const { t } = useTranslation();
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const { loading, data } = useQuery<{ roles: IRole[] }>(ROLES);

    useEffect(() => {
        if (data?.roles) {
            setOptions(data.roles.map(r => ({ value: r.id, label: getRoleLabel(r.id) })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div style={containerProps}>
            <Title>
                {title || t('role')}
            </Title>
            <Select
                placeholder={t('roles')}
                value={value}
                onChange={onChange}
                options={options}
                menuPlacement="auto"
                isClearable={true}
                isLoading={loading}
                isMulti={isMulti}
                styles={{ control: base => ({...base, marginTop: 4 }) }}
            />
        </div>
    );
}
