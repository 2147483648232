import { createPortal } from 'react-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { jsPDF } from 'jspdf';

// MUI
import { Tooltip, Fab, CircularProgress } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

// Services
import { IUserGuide } from 'services/interfaces';

// Components
import pdfFont from 'components/pdf-layout/pdf-font';
import pdfLayout from 'components/pdf-layout';
import { ReadOnlyEditor } from 'components/rich-text-editor';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- EXPORT USER GUIDE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ExportUserGuide({ userGuide }: { userGuide: IUserGuide | undefined }) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const createPDF = async () => {

        // Create new PDF
        let doc = new jsPDF('p', 'pt', 'a4');
        pdfFont(doc);

        let qlEditor = document.getElementById('ql-editor-portal');
        if (qlEditor) {

            doc.html(qlEditor, {
                autoPaging: 'text',
                margin: [105, 27.52, 105, 27.52],
                x: 0,
                y: 0,
                callback: function (doc) {

                    // Layout
                    let title = userGuide?.title || '';
                    pdfLayout(doc, title, userGuide?.guideCategory?.name);

                    // Save
                    doc.save(`${title}.pdf`, { returnPromise: true }).then(() => setLoading(false));
                }
            });
        }
    };

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                createPDF();
            }, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <>
            {userGuide?.content && (
                <>
                    {createPortal(
                        <div id="ql-editor-portal">
                            <ReadOnlyEditor value={userGuide.content} />
                        </div>,
                        document.body,
                    )}
                    <Tooltip title={t('download_pdf_version')}>
                        <Fab
                            onClick={() => setLoading(true)}
                            color="inherit"
                            disabled={loading}
                            sx={{ marginLeft: 1 }}
                        >
                            {loading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
                        </Fab>
                    </Tooltip>
                </>
            )}
        </>
    );
}
