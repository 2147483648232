import { gql } from '@apollo/client';

export const USER_GUIDES = gql`
    query UserGuides(
        $title: String
        $search: String
        $applicationId: ID
        $guideCategoryId: ID
        $guideType: GuideType
        $roleIds: [ID]
        $first: Int
        $page: Int
    ) {
        userGuides(
            title: $title
            search: $search
            applicationId: $applicationId
            guideCategoryId: $guideCategoryId
            guideType: $guideType
            roleIds: $roleIds
            first: $first
            page: $page
        ) {
            paginatorInfo {
                total
            }
            data { 
                id
                position
                title
                description
                content
                videoUrl
                guideType
                guideCategory {
                    id
                    name
                }
                application {
                    id
                    name
                }
                roles {
                    id
                    name
                }
            }
        }
    }
`;
