import { gql } from '@apollo/client';

export const ROLES = gql`
    query Roles {
        roles {
            id
            name
        }
    }
`;
