import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Apollo
import { useMutation } from '@apollo/client';
import { CREATE_GUIDE_CATEGORY } from './gql-create-guide-category';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Button, CircularProgress, Fab, Tooltip } from '@mui/material';

// Services
import { getLocales } from 'services/utils';
import { getErrorExtensions } from 'services/utils';
import { IModelTranslation, ISelectOption } from 'services/interfaces';

// Components
import ContentLayout from 'components/content-layout';
import Title from 'components/title';

// Icons
import { BackIcon } from 'icons';

// Views
import GuideCategoryForm from '../guide-category-form';

export interface IState {
    actionType: 'CREATE' | 'UPDATE';
    translations: IModelTranslation[];
    application: ISelectOption | null;
    accessibleOffline: boolean;
    parentCategory: ISelectOption | null;
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- CREATE GUIDE CATEGORY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function CreateGuideCategory() {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [createGuideCategory, { loading, error, data }] = useMutation(CREATE_GUIDE_CATEGORY);
    
    const location = useLocation();
    const locationState = location.state;

    const [state, setState] = useState<IState>({
        actionType: 'CREATE',
        translations: getLocales().map(l => ({ locale: l.value, name: '', description: '' })),
        application: null,
        accessibleOffline: false,
        parentCategory: locationState?.parentCategory ? ({ value: locationState.parentCategory.id, label: locationState.parentCategory.name }) : null,
    });

    const submitDisabled = state.translations.every(t => t.name === '');

    function onCreateCategory() {
        createGuideCategory({
            variables: {
                accessibleOffline: state.accessibleOffline,
                translations: { create: state.translations.filter(t => t.name !== '') },
                application: state.application ? { connect: state.application.value } : null,
                parentCategory: state.parentCategory ? { connect: state.parentCategory.value } : null,
            },
        });
    }

    useEffect(() => {
        if (data?.createGuideCategory) {
            
            enqueueSnackbar(t('created_successfully'), { variant: 'success' });

            if (state.parentCategory) {
                navigate('/guide-categories/' + state.parentCategory.value);
            }
            else {
                navigate('/guide-categories/' + data.createGuideCategory.id);
            }
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <ContentLayout>
            <Tooltip
                title={t('back')}
                placement="bottom"
            >
                <Fab
                    component={Link}
                    to={`/guide-categories${state.parentCategory ? ('/' + state.parentCategory.value) : ''}`}
                >
                    <BackIcon />
                </Fab>
            </Tooltip>
            <Grid
                container flexDirection="column" alignItems="center"
                style={{ marginBottom: 24, textAlign: 'center' }}
            >
                <Title titleProps={{ fontSize: 18 }}>
                    {state.parentCategory ? t('add_subcategory') : t('add_category')}
                </Title>
            </Grid>
            <GuideCategoryForm
                state={state}
                setState={setState}
                parentCategory={state.parentCategory}
            />
            <Grid
                container justifyContent="center"
                style={{ marginTop: 24 }}
            >
                <Button
                    onClick={onCreateCategory}
                    disabled={loading || submitDisabled}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                >
                    {t('add')}
                </Button>
            </Grid>
        </ContentLayout>
    );
}
