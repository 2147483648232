import { gql } from '@apollo/client';

export const GUIDE_CATEGORY = gql`
    query GuideCategory($id: ID!) {
        guideCategory(id: $id) {
            id
            name
            position
            description
            accessibleOffline
            iconUrl
            translations {
                id
                locale
                name
                description
            }
            parentCategory {
                id
                name
                position
            }
            childrenCategories {
                id
                name
                position
                application {
                    id
                    name
                }
            }
            userGuides {
                id
                title
                position
                guideType
                application {
                    id
                    name
                }
            }
            application {
                id
                name
            }
        }
    }
`;
