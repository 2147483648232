import { gql } from '@apollo/client';

export const LOGIN_WITH_CODE = gql`
    mutation LoginWithCode($loginCode: String) {
        loginWithCode(loginCode: $loginCode) {
            token
            user {
                id
                fullName
                lastName
                firstName
                email
                hasVerifiedEmail
                phoneNumber
                hasVerifiedPhoneNumber
                phoneNumberPrefix
                birthdate
                gender
                photoUrl
                locale
                permissions
                role {
                    id
                    name
                }
                company {
                    id
                    name
                    postalCode
                    city
                    applications {
                        id
                        name
                        pivot {
                            expiration
                        }
                    }
                }
                companies {
                    id
                    name
                }
            }
        }
    }
`
