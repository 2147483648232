import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Dialog, DialogContent, DialogActions, Button, Backdrop, CircularProgress } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import { LOGOUT } from './gql-logout';

// Services
import { clearStorage } from 'services/storage';
import client from 'services/apollo-client';

// Views
import { MenuContext, IMenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LOGOUT ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Logout({ onClose } : { onClose: VoidFunction }) {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { authenticated, setContext } = useContext(MenuContext) as IMenuContext;
    const [loading, setLoading] = useState(false);
    const [logout, { error, data }] = useMutation(LOGOUT);

    const onLogout = () => {
        navigate('/guide-categories');
        clearStorage();
        client.resetStore();
        onClose();
    };

    useEffect(() => {
        if (loading && authenticated) {
            setContext(prevContext => ({ ...prevContext, authenticated: false, adminMode: false }));
        }
        if (loading && !authenticated) {
            logout();
        }
        // eslint-disable-next-line
    }, [authenticated, loading]);

    useEffect(() => {
        if (data?.logout) {
            enqueueSnackbar(data.logout, { variant: 'success' });
            onLogout();
        }
        if (error) {
            onLogout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            open
            onClose={loading ? undefined : onClose}
        >
            <DialogContent>
                {t('logout_confirm')}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="inherit"
                    disabled={loading}
                >
                    {t('no')}
                </Button>
                <Button
                    onClick={() => setLoading(true)}
                    disabled={loading}
                >
                    {t('yes')}
                </Button>
            </DialogActions>
            {/* -------------------- BACKDROP -------------------- */}
            {loading && (
                <Backdrop
                    open
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Dialog>
    );
}
