import { useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// CSS
import './guide-category.css';

// MUI
import { Grid, Fab, Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, ArrowBack as BackIcon, ExpandMore as ArrowIcon } from '@mui/icons-material';

// Apollo
import { useQuery } from '@apollo/client';
import { GUIDE_CATEGORY } from './gql-guide-category';

// Services
import { IGuideCategory } from 'services/interfaces';
import { capitalizeFirstLetter, getErrorExtensions } from 'services/utils';

// Components
import Layout from 'components/layout';
import Spinner from 'components/spinner';
import Error from 'components/error';
import Title from 'components/title';
import Application from 'components/application';
import AccessibleOffline from 'components/accessible-offline';

// Icons
import { GuideCategoryIcon } from 'icons';

// Views
import ChildrenCategories from './children-categories';
import UserGuides from './user-guides';
import DeleteGuideCategory from '../delete-guide-category';
import { IMenuContext, MenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- GUIDE CATEGORY -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function GuideCategory() {

    const { t } = useTranslation();
    const { guideCategoryId } = useParams();
    const { adminMode } = useContext(MenuContext) as IMenuContext;
    const [openDelete, setOpenDelete] = useState(false);

    const { loading, error, data, refetch } = useQuery<{ guideCategory: IGuideCategory }>(GUIDE_CATEGORY, {
        variables: {
            id: guideCategoryId,
        },
        fetchPolicy: adminMode ? 'network-only' : 'cache-first',
    });

    if (loading) return <Spinner />;
    if (error) return <Error errorMessage={getErrorExtensions(error)} />;

    return (
        <Layout containerProps={{ padding: 24 }}>
            <Grid
                container
                alignItems="center"
            >
                {/* -------------------- BACK -------------------- */}
                <Tooltip title={t('back')}>
                    <Fab
                        component={Link}
                        to={`/guide-categories${data?.guideCategory?.parentCategory ? ('/' + data.guideCategory.parentCategory.id) : ''}`}
                    >
                        <BackIcon />
                    </Fab>
                </Tooltip>
                {/* -------------------- BACK TO CATEGORIES -------------------- */}
                {data?.guideCategory?.parentCategory && (
                    <Tooltip title={t('back_to_categories')}>
                        <Fab
                            component={Link}
                            to="/guide-categories"
                            sx={{ marginLeft: 1 }}
                        >
                            <GuideCategoryIcon />
                        </Fab>
                    </Tooltip>
                )}
            </Grid>
            {/* -------------------- PARENT CATEGORY -------------------- */}
            {data?.guideCategory?.parentCategory && (
                <>
                    <div className="GuideCategory-name">
                        <Title titleProps={{ color: 'gray' }}>
                            {t('category')}
                        </Title>
                        <br />
                        <h2>{data.guideCategory.parentCategory.name}</h2>
                    </div>
                    <ArrowIcon
                        style={{ marginLeft: 6, display: 'block' }}
                    />
                </>
            )}
            <div
                className="GuideCategory-name"
                style={{ marginTop: data?.guideCategory?.parentCategory ? 0 : undefined }}
            > 
                <Grid container spacing={2} alignItems="center">
                    {/* -------------------- NAME -------------------- */}
                    <Grid item xs>
                        <Title titleProps={{ color: 'gray' }}>
                            {data?.guideCategory?.parentCategory ? t('subcategory') : t('category')}
                        </Title>
                        <br />
                        <h2>{capitalizeFirstLetter(data?.guideCategory?.name)}</h2>
                    </Grid>
                    {(adminMode && data?.guideCategory) && (
                        <>
                            {/* -------------------- UPDATE -------------------- */}
                            <Grid item xs="auto">
                                <Tooltip title={t('edit')}>
                                    <Fab
                                        component={Link}
                                        to={'/guide-categories/' + data?.guideCategory?.id + '/update'}
                                        state={{ guideCategory: data?.guideCategory }}
                                    >
                                        <EditIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                            {/* -------------------- DELETE -------------------- */}
                            <Grid item xs="auto">
                                <Tooltip title={t('delete')}>
                                    <Fab onClick={() => setOpenDelete(true)}>
                                        <DeleteIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                            {openDelete && (
                                <DeleteGuideCategory
                                    guideCategory={data.guideCategory}
                                    onClose={() => setOpenDelete(false)}
                                />
                            )}
                        </>
                    )}
                </Grid>
                <div style={{ display: 'flex', alignItems: 'center' }}> 
                    {/* -------------------- ACCESSIBLE OFFLINE -------------------- */}
                    {(adminMode && data?.guideCategory?.accessibleOffline) && (
                        <AccessibleOffline />
                    )}
                    {/* -------------------- APPLICATION -------------------- */}
                    {data?.guideCategory?.application && (
                        <Application
                            applicationName={data.guideCategory.application.name}
                            type="chip"
                            containerProps={{ marginLeft: data.guideCategory?.accessibleOffline ? 6 : undefined }}
                        />
                    )}
                </div>
            </div>
            {/* -------------------- DESCRIPTION -------------------- */}
            {data?.guideCategory?.description && (
                <div className="GuideCategory-details">
                    {data.guideCategory.description}
                </div>
            )}
            {/* -------------------- CHILDREN CATEGORIES -------------------- */}
            {(
                adminMode
                || (
                    data?.guideCategory?.childrenCategories
                    && data.guideCategory.childrenCategories.length > 0
                )
            ) && (
                <ChildrenCategories
                    guideCategory={data?.guideCategory}
                    refetch={refetch}
                />
            )}
            {/* -------------------- USER GUIDES -------------------- */}
            {(
                adminMode
                || (
                    data?.guideCategory?.userGuides
                    && data.guideCategory.userGuides.length > 0
                )
            ) && (
                <UserGuides
                    guideCategory={data?.guideCategory}
                    refetch={refetch}
                />
            )}
        </Layout>
    );
}
