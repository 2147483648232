import { gql } from '@apollo/client';

export const UPDATE_USER_GUIDE = gql`
    mutation UpdateUserGuide(
        $id: ID!
        $guideType: GuideType
        $translations: UpdateUserGuideTranslationsHasMany
        $guideCategory: ConnectOrDisconnectGuideCategoryBelongsTo
        $application: ConnectOrDisconnectApplicationBelongsTo
        $roles: SyncRolesBelongsToMany
    ) {
        updateUserGuide(input: {
            id: $id
            guideType: $guideType
            translations: $translations
            guideCategory: $guideCategory
            application: $application
            roles: $roles
        }) {
            id
        }
    }
`;

export const UPDATE_USER_GUIDE_TRANSLATIONS = gql`
    mutation UpdateUserGuideTranslations(
        $id: ID!
        $translations: UpdateUserGuideTranslationsHasMany
    ) {
        updateUserGuide(input: {
            id: $id
            translations: $translations
        }) {
            id
        }
    }
`;
