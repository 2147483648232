import { IUser } from './interfaces';

// --------------------------------------------------------------------------------- \\
// ------------------------------------ CLEAR -------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const clearStorage = () => {
    let locale = localStorage.getItem('locale') || 'fr';
    localStorage.clear();
    localStorage.setItem('locale', locale);
};

// --------------------------------------------------------------------------------- \\
// ------------------------------------ LOGIN -------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const setLogin = (login: { token: string, user: IUser }) => {
    localStorage.setItem('token', login.token);
    localStorage.setItem('user', JSON.stringify(login.user));
};

// --------------------------------------------------------------------------------- \\
// ----------------------------------- TOKEN --------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getStoredToken = () => {
    return localStorage.getItem('token');
};

// --------------------------------------------------------------------------------- \\
// ------------------------------------ USER --------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getStoredUser = () => {

    let user = localStorage.getItem('user');

    if (user) {
        return JSON.parse(user) as IUser;
    }
    return null;
};

export const setStoredUser = (user: IUser) => {
    localStorage.setItem('user', JSON.stringify(user));
};
