import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// Components
import Title from 'components/title';

// Views
import { MenuContext, IMenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- TOGGLE APPLICATION ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ToggleApplication() {

    const { t } = useTranslation();
    const { applicationId, setContext } = useContext(MenuContext) as IMenuContext;

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(0.5),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    return (
        <div style={{ padding: 16 }}>
            <Title>
                {t('application')}
            </Title>
            <StyledToggleButtonGroup
                value={applicationId}
                onChange={(event, value) => setContext(prevContext => ({ ...prevContext, applicationId: value || '' }))}
                size="small"
                orientation="vertical"
                exclusive
                sx={{ marginTop: 1, display: 'flex', border: '1px solid gray' }}
            >
                <ToggleButton
                    value="1"
                    color="primary"
                    style={{ flex: 1 }}
                >
                    Zero Barrier
                </ToggleButton>
                <ToggleButton
                    value="2"
                    color="magenta"
                    style={{ flex: 1 }}
                >
                    Happ'In Co
                </ToggleButton>
            </StyledToggleButtonGroup>
        </div>
    );
}
