import { useTranslation } from 'react-i18next';

// MUI
import { TextField, InputAdornment, CircularProgress } from '@mui/material';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ SEARCH INPUT -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SearchInput({
    value,
    onChange,
    onSearch,
    label,
    loading,
    inputProps,
} : {
    value: string,
    onChange: (value: string ) => void,
    onSearch: (value: string) => void,
    label?: string,
    loading?: boolean,
    inputProps?: React.CSSProperties,
}) {

    const { t } = useTranslation();

    return (
        <TextField
            placeholder={label || `${t('research')}...`}
            value={value}
            onChange={e => onChange(e.target.value)}
            onKeyPress={e => e.key === 'Enter' ? onSearch(value) : undefined}
            InputProps={{
                style: {
                    ...inputProps,
                    backgroundColor: 'white',
                },
                endAdornment: (
                    loading && (
                        <InputAdornment position="end">
                            <CircularProgress size={20} color="inherit" />
                        </InputAdornment>
                    )
                )
            }}
            type="text"
            variant="outlined"
            size="small"
            fullWidth
        />
    );
}
