import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, Tooltip, Fab } from '@mui/material';

// Services
import { IModelTranslation } from 'services/interfaces';
import { getLocales } from 'services/utils';

// Components
import Title from 'components/title';
import MobileTextField from 'components/mobile-text-field';

// CSS
import styles from './TranslationsForm.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- TRANSLATIONS FORM ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

interface ITranslationFormProps {
    translations: IModelTranslation[];
    setTranslations: (translations: IModelTranslation[]) => void;
    descriptionLabel?: string;
    disablebTitle?: boolean;
    disabledName?: boolean;
    disabledDescription?: boolean;
    nameMultiline?: boolean;
}

export default function TranslationsForm(props: ITranslationFormProps) {

    const { t } = useTranslation();
    const locales = getLocales();
    const [selectedLocale, setSelectedLocale] = useState('fr');

    return (
        <div className={styles.container}>
            {/* -------------------- LOCALES -------------------- */}
            <Grid padding={1}>
                <Grid
                    container
                    alignItems="center"
                    spacing={1}
                >
                    {locales.map((locale, index) => (
                        <Grid
                            key={index}
                            item xs="auto"
                        >
                            <Tooltip
                                title={locale.label}
                                enterDelay={800}
                                enterNextDelay={800}
                                leaveDelay={0}
                            >
                                <Fab
                                    onClick={e => setSelectedLocale(locale.value)}
                                    color={selectedLocale === locale.value ? 'primary' : 'inherit'}
                                >
                                    {locale.value.includes('-') ? locale.value.split('-')[1] : locale.value}
                                </Fab>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {/* -------------------- NAME -------------------- */}
            {!props.disabledName && (
                <Grid padding={1}>
                    {!props.disablebTitle && (
                        <Title required>
                            {t('name')}
                        </Title>
                    )}
                    <MobileTextField
                        name="name"
                        value={props.translations.find(t => t.locale === selectedLocale)?.name || ''}
                        onChange={e => props.setTranslations(props.translations.map(t => t.locale === selectedLocale ? { ...t, name: e.target.value } : t))}
                        placeholder={t('name')}
                        multiline={props.nameMultiline}
                        rows={props.nameMultiline ? 3 : undefined}
                    />
                </Grid>
            )}
            {/* -------------------- DESCRIPTION -------------------- */}
            {!props.disabledDescription && (
                <Grid padding={1}>
                    {!props.disablebTitle && (
                        <Title>
                            {t('description')}
                        </Title>
                    )}
                    <MobileTextField
                        name="description"
                        value={props.translations.find(t => t.locale === selectedLocale)?.description || ''}
                        onChange={e => props.setTranslations(props.translations.map(t => t.locale === selectedLocale ? { ...t, description: e.target.value } : t))}
                        placeholder={t('description')}
                        multiline
                        rows={3}
                    />
                </Grid>
            )}
        </div>
    );
}
