
// Services
import { getStoredUser } from './storage';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SUPER ADMIN ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const isSuperAdmin = () => {
    const storedUser = getStoredUser();
    return !! (storedUser?.role?.id && Number(storedUser.role.id) === 1);
};
