
// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- COLORS ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const primaryLight = '#42a5f5';
export const primaryMain = '#1976d2';
export const primaryDark = '#1565c0';

export const magentaLight = '#d80080';
export const magentaMain = '#ac0066';
export const magentaDark = '#890f61';

export const successLight = '#4caf50';
export const successMain = '#2e7d32';
export const successDark = '#1b5e20';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------- FORBIDDEN KEYS NUMBER INPUT ------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export const forbiddenKeysNumberInput = ['-', '+', 'e'];
