
// MUI
import { Grid } from '@mui/material';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- LAYOUT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Layout({ children, containerProps } : { children: React.ReactNode, containerProps?: React.CSSProperties }) {
    return (
        <Grid
            container
            style={containerProps}
        >
            <Grid
                item xs={0} lg={1} xl={2}
            />
            <Grid item xs={12} lg={10} xl={8}>
                {children}
            </Grid>
        </Grid>
    );
}
