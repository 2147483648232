import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Button, CircularProgress, Tooltip, Fab } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import { UPDATE_USER_GUIDE } from './gql-update-user-guide';

// Services
import { IUserGuide } from 'services/interfaces';
import { EGuideType } from 'services/enums';
import { getLocales, getErrorExtensions, getApplicationName, getRoleLabel } from 'services/utils';

// Icons
import { BackIcon } from 'icons';

// Components
import ContentLayout from 'components/content-layout';
import Title from 'components/title';

// Views
import UserGuideForm from '../user-guide-form';
import { IState } from '../create-user-guide';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- UPDATE USER GUIDE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UpdateUserGuide() {

    const navigate = useNavigate();
    const location = useLocation();
    const userGuide : IUserGuide | undefined = location?.state?.userGuide;

    const { t } = useTranslation();
    const { guideCategoryId, userGuideId } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const [updateUserGuide, { loading, error, data }] = useMutation(UPDATE_USER_GUIDE);

    const defaultTranslations = getLocales().map(locale => {
        let t = userGuide?.translations?.find(t => t.locale === locale.value);
        if (t) {
            return ({ id: t.id, locale: t.locale, title: t.title, description: t.description, content: t.content });
        }
        return ({ locale: locale.value, title: '', description: '', content: '' });
    });

    const defaultApplication = userGuide?.application
        ? ({ value: userGuide.application.id, label: getApplicationName(userGuide.application.name) })
        : (userGuide?.guideCategory?.application
            ? ({ value: userGuide.guideCategory.application.id, label: getApplicationName(userGuide.guideCategory.application.name) })
            : null
        );

    const [state, setState] = useState<IState>({
        actionType: 'UPDATE',
        translations: defaultTranslations,
        guideType: userGuide?.guideType ? ({ value: userGuide.guideType, label: t(userGuide.guideType.toLowerCase()) }) : ({ value: EGuideType.TECHNICAL, label: t('technical') }),
        guideCategory: userGuide?.guideCategory ? ({ value: userGuide.guideCategory.id, label: userGuide.guideCategory.name, application: userGuide.guideCategory.application }) : null,
        application: defaultApplication,
        roles: userGuide?.roles ? userGuide.roles.map(r => ({ value: r.id, label: getRoleLabel(r.id) })) : [],
    });

    const submitDisabled = state.translations.every(t => t.title === '') || state.guideCategory == null;

    const onUpdateUserGuide = () => {
        updateUserGuide({
            variables: {
                id: userGuideId,
                application: state.application ? { connect: state.application.value } : (userGuide?.application ? { disconnect: true } : null),
                guideType: state.guideType.value,
                guideCategory: state.guideCategory ? { connect: state.guideCategory.value } : null,
                roles: { sync: state.roles.map(r => r.value) },
                translations: {
                    create: state.translations.filter(t => t.id == null && t.title !== ''),
                    update: state.translations.filter(t => t.id != null && t.title !== ''),
                    delete: state.translations.filter(t => t.id != null && t.title === '' && (t.content === '' || t.content === '<p><br></p>')).map(t => t.id),
                },
            },
        });
    };

    useEffect(() => {
        if (data?.updateUserGuide) {
            enqueueSnackbar(t('updated_successfully'), { variant: 'success' });
            navigate('/guide-categories/' + state.guideCategory?.value + '/user-guides/' + userGuideId);
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <ContentLayout>
            <Tooltip
                title={t('back')}
                placement="bottom"
            >
                <Fab 
                    component={Link}
                    to={`/guide-categories/${guideCategoryId}/user-guides/${userGuideId}`}
                >
                    <BackIcon />
                </Fab>
            </Tooltip>
            <div style={{ textAlign: 'center', marginBottom: 24 }}>
                <Title titleProps={{ fontSize: 18 }}>
                    {t('edit_user_guide')}
                </Title>
            </div>
            <UserGuideForm
                state={state}
                setState={setState}
            />
            <Grid
                container
                justifyContent="center"
                marginTop={3}
            >
                <Button
                    onClick={onUpdateUserGuide}
                    disabled={loading || submitDisabled}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                >
                    {t('save')}
                </Button>
            </Grid>
        </ContentLayout>
    );
}
