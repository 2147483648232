import { gql } from '@apollo/client';

export const UPDATE_GUIDE_CATEGORY = gql`
    mutation UpdateGuideCategory(
        $id: ID!
        $accessibleOffline: Boolean
        $translations: UpdateTranslationsHasMany
        $parentCategory: ConnectOrDisconnectGuideCategoryBelongsTo
        $application: ConnectOrDisconnectApplicationBelongsTo
    ) {
        updateGuideCategory(input: {
            id: $id
            accessibleOffline: $accessibleOffline
            translations: $translations
            parentCategory: $parentCategory
            application: $application
        }) {
            id
        }
    }
`;

export const REORDER_USER_GUIDES = gql`
    mutation ReorderUserGuides(
        $id: ID!
        $userGuides: UpdateUserGuidesHasManyInput
    ) {
        updateGuideCategory(input: {
            id: $id
            userGuides: $userGuides
        }) {
            id
        }
    }
`;
