import { jsPDF } from 'jspdf';

// Assets
import DiscoviaLogo from 'assets/images/discovia-logo.png';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- PDF LAYOUT -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function pdfLayout(doc: jsPDF, title: string, category: string | undefined) {

    for (let i = 0; i < doc.getNumberOfPages(); i += 1) {

        doc.setPage(i + 1);

        doc.setFont('Roboto', 'normal');
        doc.setFontSize(11);

        /* * *
        * Header
        */
       
        // Gray Headband
        doc.setFillColor(211, 211, 211);
        doc.rect(0, 0, 595.28, 62.36, 'F');

        // Category
        if (category) {
            let resizedCategory = doc.splitTextToSize(category.toUpperCase(), 300);
            let topY = resizedCategory.length > 1 ? 42.52 : 52.52;
            doc.text(resizedCategory, 42.52, topY, { align: 'left' });
        }

        // Logo
        doc.addImage(DiscoviaLogo, 'PNG', 439.37, 22.52, 113, 30);

        // Title
        let resizedTitle = doc.splitTextToSize(title.toUpperCase(), 453.55);
        doc.text(resizedTitle, 297.64, 85.04, { align: 'center' });

        // Divider
        let dividerY = resizedTitle.length > 1 ? 107.72 : 96.39;
        doc.setLineWidth(0.2);
        doc.setDrawColor(128, 128, 128);
        doc.line(0, dividerY, 595.28, dividerY);

        /* * *
        * Footer
        */

        // Divider
        doc.line(0, 765.36, 595.28, 765.36);

        // Page number
        doc.setTextColor(0);
        doc.text(`${i + 1} / ${doc.getNumberOfPages()}`, 527.25, 782.37);
    }
}
