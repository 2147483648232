import jsPDF from 'jspdf';

// Assets
import RobotoRegular from 'assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoMedium from 'assets/fonts/Roboto/Roboto-Medium.ttf';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- PDF FONT ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function pdfFont(doc: jsPDF) {
    doc.addFont(RobotoRegular, 'Roboto', 'normal');
    doc.addFont(RobotoMedium, 'Roboto', 'bold');
    doc.setFont('Roboto');
}
