import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Button, CircularProgress, Tooltip, Fab } from '@mui/material';

// Apollo
import { useMutation } from '@apollo/client';
import { CREATE_USER_GUIDE } from './gql-create-user-guide';

// Services
import { IUserGuideTranslation, ISelectOption, ISelectOptionWithApp } from 'services/interfaces';
import { getLocales, getErrorExtensions, getApplicationName } from 'services/utils';
import { EGuideType } from 'services/enums';

// Icons
import { BackIcon } from 'icons';

// Components
import ContentLayout from 'components/content-layout';
import Title from 'components/title';

// Views
import UserGuideForm from '../user-guide-form';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- CREATE USER GUIDE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export interface IState {
    actionType: 'CREATE' | 'UPDATE';
    translations: IUserGuideTranslation[];
    guideType: ISelectOption;
    guideCategory: ISelectOptionWithApp | null;
    application: ISelectOption | null;
    roles: ISelectOption[];
}

export default function CreateUserGuide() {

    const navigate = useNavigate();

    const location = useLocation();
    const locationState = location.state;

    const { t } = useTranslation();
    const { guideCategoryId } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const [createUserGuide, { loading, error, data }] = useMutation(CREATE_USER_GUIDE);

    const [state, setState] = useState<IState>({
        actionType: 'CREATE',
        translations: getLocales().map(l => ({ locale: l.value, title: '', description: '' })),
        guideType: ({ value: EGuideType.TECHNICAL, label: t('technical') }),
        guideCategory: locationState?.guideCategory ? ({ value: locationState.guideCategory.id, label: locationState.guideCategory.name }) : null,
        application: locationState?.guideCategory?.application ? ({ value: locationState.guideCategory.application.id, label: getApplicationName(locationState.guideCategory.application.name) }) : null,
        roles: [],
    });

    const onCreateUserGuide = () => {
        createUserGuide({
            variables: {
                translations: { create: state.translations.filter(t => t.title !== '') },
                application: state.application ? { connect: state.application.value } : null,
                guideType: state.guideType.value,
                guideCategory: state.guideCategory ? { connect: state.guideCategory.value } : null,
                roles: { sync: state.roles.map(r => r.value) },
            },
        });
    };

    useEffect(() => {
        if (data?.createUserGuide) {
            enqueueSnackbar(t('created_successfully'), { variant: 'success' });
            navigate('/guide-categories/' + guideCategoryId);
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <ContentLayout>
            <Tooltip
                title={t('back')}
                placement="bottom"
            >
                <Fab
                    component={Link}
                    to={'/guide-categories/' + guideCategoryId}
                >
                    <BackIcon />
                </Fab>
            </Tooltip>
            <div style={{ textAlign: 'center', marginBottom: 24 }}>
                <Title titleProps={{ fontSize: 18 }}>
                    {t('add_user_guide')}
                </Title>
            </div>
            <UserGuideForm
                state={state}
                setState={setState}
            />
            <Grid
                container
                justifyContent="center"
                marginTop={3}
            >
                <Button
                    onClick={onCreateUserGuide}
                    disabled={loading || state.translations.every(t => t.title === '')}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                >
                    {t('add')}
                </Button>
            </Grid>
        </ContentLayout>
    );
}
