import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// Services
import { EGuideType } from 'services/enums';

// Components
import Title from 'components/title';

// Views
import { MenuContext, IMenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- TOGGLE GUIDE TYPE ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ToggleGuideType() {

    const { t } = useTranslation();
    const { guideType, setContext } = useContext(MenuContext) as IMenuContext;

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            margin: theme.spacing(0.5),
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.shape.borderRadius,
            },
            '&:first-of-type': {
                borderRadius: theme.shape.borderRadius,
            },
        },
    }));

    return (
        <div style={{ padding: 16 }}>
            <Title>
                {(t('user_guide.2'))}
            </Title>
            <StyledToggleButtonGroup
                value={guideType}
                onChange={(event, value) => setContext(prevContext => ({ ...prevContext, guideType: value }))}
                size="small"
                orientation="vertical"
                exclusive
                sx={{ marginTop: 1, display: 'flex', border: '1px solid gray' }}
            >
                <ToggleButton
                    value={EGuideType.TECHNICAL}
                    color="primary"
                    style={{ flex: 1 }}
                >
                    {t('technical')}
                </ToggleButton>
                <ToggleButton
                    value={EGuideType.PEDAGOGICAL}
                    color="magenta"
                    style={{ flex: 1 }}
                >
                    {t('pedagogical')}
                </ToggleButton>
            </StyledToggleButtonGroup>
        </div>
    );
}
