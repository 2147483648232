import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Button, CircularProgress, Backdrop, TextField, InputAdornment, IconButton } from '@mui/material';
import { AccountCircle as AccountIcon, Lock as PasswordIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';

// Apollo
import { useMutation } from '@apollo/client';
import { LOGIN } from './gql-login';

// Services
import { setLogin } from 'services/storage';
import { getErrorExtensions } from 'services/utils';
import client from 'services/apollo-client';

// Components
import Title from 'components/title';

// Views
import { MenuContext, IMenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- LOGIN ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

interface IFormInput {
    login: string;
    password: string;
}

export default function Login() {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit } = useForm<IFormInput>();
    const { authenticated, setContext } = useContext(MenuContext) as IMenuContext;
    const [inputType, setInputType] = useState<'password'|'text'>('password');
    const [login, { loading, error, data }] = useMutation(LOGIN);

    const onLogin = (formData: IFormInput) => {
        login({
            variables: {
                login: formData.login,
                password: formData.password,
            },
        });
    };

    useEffect(() => {
        if (authenticated) {
            navigate('/guide-categories');
            setLogin(data.login);
            client.resetStore();
        }
        if (data?.login) {
            setContext(prevContext => ({ ...prevContext, authenticated: true }));
        }
        if (error) {
            enqueueSnackbar(getErrorExtensions(error), { variant: 'error' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, authenticated]);

    return (
        <>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                paddingX={2}
                paddingY={6}
            >
                <Grid
                    width={400}
                    maxWidth={400}
                >
                    <form onSubmit={handleSubmit(onLogin)}>
                        <Grid textAlign="center">
                            <Title titleProps={{ fontSize: 22 }}>
                                {t('login')}
                            </Title>
                        </Grid>
                        <Grid marginTop={6}>
                            <Title required>
                                {t('email_or_phone')}
                            </Title>
                            <TextField
                                {...register('login', { required: true })}
                                placeholder={t('email_or_phone')}
                                autoComplete="email"
                                InputProps={{
                                    style: {
                                        backgroundColor: 'white',
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid marginTop={3}>
                            <Title required>
                                {t('password')}
                            </Title>
                            <TextField
                                {...register('password', { required: true })}
                                placeholder={t('password')}
                                type={inputType}
                                autoComplete="current-password"
                                InputProps={{
                                    style: {
                                        backgroundColor: 'white',
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}
                                                style={{ padding: 0 }}
                                            >
                                                {inputType === 'password' ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid
                            marginTop={4}
                            textAlign="center"
                        >
                            <Button
                                type="submit"
                                fullWidth
                            >
                                {t('sign_in')}
                            </Button>
                        </Grid>
                        <Grid
                            marginTop={2}
                            textAlign="center"
                        >
                            <Button
                                component={Link}
                                to="/guide-categories"
                                color="success"
                                variant="outlined"
                                fullWidth
                            >
                                {t('back_to_homepage')}
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            {/* -------------------- BACKDROP -------------------- */}
            {loading && (
                <Backdrop
                    open
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </>
    );
}
