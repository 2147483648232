import React, { forwardRef, useEffect, useState } from 'react';

// MUI
import { TextField, TextFieldProps } from '@mui/material';

// Services
import { useMediaQueries } from 'services/hooks';

// CSS
import './MobileTextField.css';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MOBILE TEXT FIELD ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const MobileTextField = forwardRef(function MobileTextField(props: TextFieldProps, ref) {

    const { mediaDesktop } = useMediaQueries();
    const [open, setOpen] = useState(false);

    const onKeyDown = (event: React.ChangeEvent<HTMLInputElement>) => {

        // Avoid form submitting
        event.preventDefault();

        let input = document.getElementById(`TextField-${props.name}`) as HTMLInputElement;
        input.value = event.target.value;

        setOpen(false);
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {

        let input = document.getElementById(`TextField-${props.name}`) as HTMLInputElement;
        input.value = event.target.value;

        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            document.getElementById('TextField-drawer')?.focus();
        }
    }, [open]);

    return (
        <>
            <TextField
                {...props}
                id={`TextField-${props.name}`}
                inputRef={ref}
                onFocus={mediaDesktop ? undefined : () => setOpen(true)}
            />
            {(open && mediaDesktop === false) && (
                <div className="MobileTextField-drawer">
                    <div>
                        <TextField
                            {...props}
                            id="TextField-drawer"
                            inputRef={ref}
                            onKeyDown={(e: any) => e.key === 'Enter' ? onKeyDown(e) : undefined}
                            onBlur={e => onBlur(e)}
                        />
                    </div>
                </div>
            )}
        </>
    );
});

export default MobileTextField;
