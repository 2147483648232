import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

// i18next
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Notistack
import { SnackbarProvider } from 'notistack';

// Apollo Client
import { ApolloProvider } from '@apollo/client';
import client from 'services/apollo-client';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

// CSS
import './index.css';

// Main App
import App from './app';

// Roboto Font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Translation files
import translationFR from 'assets/translations/fr.translations.json';
import translationFR_FALC from 'assets/translations/fr-falc.translations.json';
import translationEN from 'assets/translations/en.translations.json';
import translationDE from 'assets/translations/de.translations.json';
import translationSL from 'assets/translations/sl.translations.json';

// --------------------------------------------------------------------------------- \\
// ------------------------------------ I18NEXT ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

i18next.use(initReactI18next).init({
    resources: {
        "fr": { translation: translationFR },
        "fr-FALC": { translation: translationFR_FALC },
        "en": { translation: translationEN },
        "de": { translation: translationDE },
        "sl": { translation: translationSL },
    },
    lng: 'fr',
    fallbackLng: {
        'fr-FALC': ['fr'],
        default: ['en'],
    },
});

// --------------------------------------------------------------------------------- \\
// ------------------------------ MUI COMPONENTS ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

declare module '@mui/material/ToggleButton' {
    interface ToggleButtonPropsColorOverrides {
        magenta: true;
    }
}

// --------------------------------------------------------------------------------- \\
// -------------------------------- MUI PALETTE ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

declare module '@mui/material/styles' {
    interface Palette {
        magenta: Palette['primary'];
    }
    interface PaletteOptions {
        magenta: PaletteOptions['primary'];
    }
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MUI THEME ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const theme = createTheme({
    palette: {
        magenta: {
            light: '#d80080',
            main: '#ac0066',
            dark: '#890f61',
            contrastText: '#fff',
        },
    },
    components: {
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    zIndex: 0,
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterDelay: 400,
                leaveDelay: 200,
                placement: 'top',
            },
            styleOverrides: {
                tooltip: {
                    fontSize: 14,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                color: 'success',
                variant: 'contained',
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: 'success',
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                size: 'small',
                variant: 'outlined',
                InputProps: {
                    style: {
                        backgroundColor: 'white',
                    },
                },
            },
        },
        MuiListItemButton: {
            defaultProps: {
                style: {
                    minHeight: 40,
                },
            },
            styleOverrides: {
                "root": {
                    "&.Mui-selected": {
                        "backgroundColor": "rgba(0, 0, 0, 0.08)",
                    },
                    "&.Mui-selected:hover": {
                        "backgroundColor": "rgba(0, 0, 0, 0.04)",
                    },
                }
            },
        },
    },
});

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- ROOT ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
