import { useContext } from 'react';
import { useTranslation } from 'react-i18next'

// MUI
import { Divider, FormControlLabel, Switch } from '@mui/material';

// Views
import { MenuContext, IMenuContext } from 'app';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ADMIN MODE --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AdminMode() {

    const { t } = useTranslation();
    const { adminMode, setContext } = useContext(MenuContext) as IMenuContext;

    return (
        <>
            <div style={{ padding: 16 }}>
                <FormControlLabel
                    label={t('admin_mode')}
                    control={
                        <Switch
                            checked={adminMode}
                            onChange={(e, checked) => setContext(prevContext => ({ ...prevContext, adminMode: checked }))}
                        />
                    }
                />
            </div>
            <Divider />
        </>
    );
}
