import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// MUI
import { Grid, Tooltip, Fab, ListItemButton } from '@mui/material';
import { Search as SearchIcon, SearchOff as SearchOffIcon, Add as AddIcon } from '@mui/icons-material';

// Apollo
import { useQuery, ApolloError } from '@apollo/client';
import { USER_GUIDES } from './gql-user-guides';

// Services
import { IUserGuide } from 'services/interfaces';
import { capitalizeFirstLetter } from 'services/utils';
import { EGuideType } from 'services/enums';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Layout from 'components/layout';
import SearchInput from 'components/search-input';
import NoResult from 'components/no-result';
import Application from 'components/application';

// Views
import { IMenuContext, MenuContext } from 'app';

// Icons
import { PedagogicalGuideIcon, TechnicalGuideIcon } from 'icons';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ USER GUIDES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface IState {
    searchValue: string;
    search: string;
    userGuides: IUserGuide[];
    totalCount: number;
}

export default function UserGuides() {

    const { t } = useTranslation();
    const { adminMode, applicationId, guideType } = useContext(MenuContext) as IMenuContext;

    const [state, setState] = useState<IState>({
        searchValue: '',
        search: '',
        userGuides: [],
        totalCount: 0,
    });

    const { loading, error, data } = useQuery(USER_GUIDES, {
        variables: {
            page: 1,
            first: 20,
            search: '%' + state.search + '%',
            applicationId: applicationId,
            guideType: guideType,
        },
        fetchPolicy: adminMode ? 'network-only' : 'cache-first',
    });

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <Layout>
            <Grid
                padding={2}
                spacing={2}
                container
                alignItems="center"
            >
                {/* -------------------- SEARCH INPUT -------------------- */}
                <Grid item xs={12} sm>
                    <SearchInput
                        value={state.searchValue}
                        onChange={searchValue => setState(prevState => ({ ...prevState, searchValue }))}
                        onSearch={search => setState(prevState => ({ ...prevState, search }))}
                        loading={loading}
                    />
                </Grid>
                {/* -------------------- START SEARCH -------------------- */}
                <Grid item xs="auto">
                    <Tooltip title={t('start_search')}>
                        <Fab onClick={() => setState(prevState => ({ ...prevState, search: prevState.searchValue }))}>
                            <SearchIcon />
                        </Fab>
                    </Tooltip>
                </Grid>
                {/* -------------------- CANCEL SEARCH -------------------- */}
                <Grid item xs="auto">
                    <Tooltip title={t('cancel_research')}>
                        <Fab onClick={() => setState(prevState => ({ ...prevState, search: '', searchValue: '' }))}>
                            <SearchOffIcon />
                        </Fab>
                    </Tooltip>
                </Grid>
                {/* -------------------- CREATE -------------------- */}
                {adminMode && (
                    <Grid item xs="auto">
                        <Tooltip title={t('add_user_guide')}>
                            <Fab
                                component={Link}
                                to="/user-guides/create"
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            {/* -------------------- LIST -------------------- */}
            <UserGuideList
                loading={loading}
                error={error}
                userGuides={data?.userGuides?.data}
            />
        </Layout>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- USER GUIDE LIST ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export function UserGuideList({
    loading,
    error,
    userGuides,
} : {
    loading: boolean,
    error: ApolloError | undefined,
    userGuides: IUserGuide[],
}) {

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            {(
                userGuides
                && userGuides.length > 0
            ) ? (
                userGuides.map(userGuide => (
                    <div
                        key={userGuide.id}
                        className="ListItemCard"
                    >
                        <ListItemButton
                            component={Link}
                            to={`/guide-categories/${userGuide.guideCategory?.id}/user-guides/${userGuide.id}`}
                        >
                            <div className="ListItemCard-label">
                                {userGuide?.guideType === EGuideType.PEDAGOGICAL ? (
                                    <PedagogicalGuideIcon />
                                ) : (
                                    <TechnicalGuideIcon />
                                )}
                                <span style={{ marginLeft: 8 }}>
                                    {capitalizeFirstLetter(userGuide.title)}
                                </span>
                            </div>
                            {userGuide?.application && (
                                <Application
                                    applicationName={userGuide.application.name}
                                    type="pellet"
                                />
                            )}
                        </ListItemButton>
                    </div>
                ))
            ) : (
                <NoResult />
            )}
        </>
    );
}
