import { useTranslation } from 'react-i18next';

// MUI
import { WarningRounded as ErrorIcon } from '@mui/icons-material';

// CSS
import './error.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- ERROR ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Error({ errorMessage } : { errorMessage?: string }) {

    const { t } = useTranslation();

    return (
        <div className="error">
            <ErrorIcon
                fontSize="large"
            />
            <span>
                {errorMessage || t('an_error_has_occured')}
            </span>
        </div>
    );
}
