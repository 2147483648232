import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

// CSS
import './user-guide.css';

// MUI
import { Tooltip, Fab, Grid } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, ExpandMore as ArrowIcon } from '@mui/icons-material';

// Apollo
import { useQuery } from '@apollo/client';
import { USER_GUIDE } from './gql-user-guide';

// Services
import { IUserGuide } from 'services/interfaces';
import { capitalizeFirstLetter, getErrorExtensions, getRoleLabel } from 'services/utils';

// Components
import Layout from 'components/layout';
import Spinner from 'components/spinner';
import Error from 'components/error';
import Title from 'components/title';
import Application from 'components/application';
import GuideType from 'components/guide-type';

// Icons
import { BackIcon, GuideCategoryIcon } from 'icons';

// Views
import UserGuideTranslations from './user-guide-translations';
import DeleteUserGuide from '../delete-user-guide';
import { IMenuContext, MenuContext } from 'app';
import ExportUserGuide from './export-user-guide';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ USER GUIDE ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function UserGuide() {

    const { t } = useTranslation();
    const { guideCategoryId, userGuideId } = useParams();
    const { adminMode } = useContext(MenuContext) as IMenuContext;
    const [openDelete, setOpenDelete] = useState(false);

    const { loading, error, data, refetch } = useQuery<{ userGuide: IUserGuide }>(USER_GUIDE, {
        variables: { id: userGuideId },
        fetchPolicy: adminMode ? 'network-only' : 'cache-first',
    });

    if (loading) return <Spinner />;
    if (error) return <Error errorMessage={getErrorExtensions(error)} />;

    return (
        <Layout containerProps={{ padding: 24 }}>
            <Grid
                container
                alignItems="center"
            >
                {/* -------------------- BACK -------------------- */}
                <Tooltip title={t('back')}>
                    <Fab
                        component={Link}
                        to={'/guide-categories/' + guideCategoryId}
                    >
                        <BackIcon />
                    </Fab>
                </Tooltip>
                {/* -------------------- BACK TO CATEGORIES -------------------- */}
                <Tooltip title={t('back_to_categories')}>
                    <Fab
                        component={Link}
                        to="/guide-categories"
                        sx={{ marginLeft: 1 }}
                    >
                        <GuideCategoryIcon />
                    </Fab>
                </Tooltip>
                <ExportUserGuide userGuide={data?.userGuide} />
            </Grid>
            {/* -------------------- GUIDE CATEGORY -------------------- */}
            {data?.userGuide?.guideCategory && (
                <>
                    <div className="GuideCategory-name">
                        <Title titleProps={{ color: 'gray' }}>
                            {t('category')}
                        </Title>
                        <br />
                        <h2>{data.userGuide.guideCategory.name}</h2>
                    </div>
                    <ArrowIcon
                        style={{ marginLeft: 6, display: 'block' }}
                    />
                </>
            )}
            <div
                className="GuideCategory-name"
                style={{ marginTop: 0 }}
            > 
                <Grid
                    spacing={2}
                    container
                    alignItems="center"
                >
                    {/* -------------------- NAME -------------------- */}
                    <Grid item xs>
                        <Title titleProps={{ color: 'gray' }}>
                            {t('user_guide.2')}
                        </Title>
                        <br />
                        <h2>{capitalizeFirstLetter(data?.userGuide?.title)}</h2>
                    </Grid>
                    {(adminMode && data?.userGuide) && (
                        <>
                            {/* -------------------- UPDATE -------------------- */}
                            <Grid item xs="auto">
                                <Tooltip title={t('edit')}>
                                    <Fab
                                        component={Link}
                                        to={`/guide-categories/${guideCategoryId}/user-guides/${userGuideId}/update`}
                                        state={{ userGuide: data?.userGuide }}
                                    >
                                        <EditIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                            {/* -------------------- DELETE -------------------- */}
                            <Grid item xs="auto">
                                <Tooltip title={t('delete')}>
                                    <Fab onClick={() => setOpenDelete(true)}>
                                        <DeleteIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                            {openDelete && (
                                <DeleteUserGuide
                                    userGuide={data.userGuide}
                                    onClose={() => setOpenDelete(false)}
                                />
                            )}
                        </>
                    )}
                </Grid>
                <Grid padding={1}>
                    <Grid
                        spacing={1}
                        container
                        alignItems="center"
                    >
                        {/* -------------------- GUIDE TYPE -------------------- */}
                        {data?.userGuide?.guideType && (
                            <Grid item xs="auto">
                                <GuideType guideType={data.userGuide.guideType} />
                            </Grid>
                        )}
                        {/* -------------------- APPLICATION -------------------- */}
                        {data?.userGuide?.application && (
                            <Grid item xs="auto">
                                <Application
                                    applicationName={data.userGuide.application.name}
                                    type="chip"
                                />
                            </Grid>
                        )}
                        {/* -------------------- ROLES -------------------- */}
                        {(
                            data?.userGuide?.roles
                            && data.userGuide.roles.length > 0
                        ) && (
                            data.userGuide.roles.map(role => (
                                <Grid
                                    key={role.id}
                                    item xs="auto"
                                >
                                    <div 
                                        className="chip"
                                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', color: 'rgb(60, 60, 60)' }}
                                    >
                                        {getRoleLabel(role.id)}
                                    </div>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Grid>
            </div>
            <UserGuideTranslations
                userGuide={data?.userGuide}
                refetch={refetch}
            />
        </Layout>
    );
}
