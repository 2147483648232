import { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';

// MUI
import { Grid, Tooltip, Fab, ListItemButton, Button } from '@mui/material';
import {
    Search as SearchIcon,
    SearchOff as SearchOffIcon,
    MoveDown as SortIcon,
    Add as AddIcon,
    Login as LoginIcon,
} from '@mui/icons-material';

// Apollo
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { GUIDE_CATEGORIES, REORDER_GUIDE_CATEGORIES } from './gql-guide-categories';
import { USER_GUIDES } from '../user-guides/gql-user-guides';

// Services
import { getStoredToken } from 'services/storage';
import { IGuideCategory } from 'services/interfaces';
import { capitalizeFirstLetter } from 'services/utils';

// Components
import Error from 'components/error';
import Layout from 'components/layout';
import SearchInput from 'components/search-input';
import AnimatedBadge from 'components/animated-badge';
import Spinner from 'components/spinner';
import NoResult from 'components/no-result';
import Application from 'components/application';
import AccessibleOffline from 'components/accessible-offline';
import { SortableList, SortableItem } from 'components/sortable-list';

// Icons
import { GuideCategoryIcon } from 'icons';

// Views
import { UserGuideList } from 'views/user-guides';
import { IMenuContext, MenuContext } from 'app';

/* * *
* Interfaces
*/

interface IState {
    searchValue: string;
    search: string;
    guideCategories: IGuideCategory[];
    totalCount: number;
    sorting: boolean;
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------- GUIDE CATEGORIES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function GuideCategories() {

    const storedToken = getStoredToken();
    const { t } = useTranslation();
    const { state: locationState } = useLocation();
    const { applicationId, adminMode, guideType, setContext } = useContext(MenuContext) as IMenuContext;

    const [state, setState] = useState<IState>({
        search: '',
        searchValue: '',
        guideCategories: [],
        totalCount: 0,
        sorting: false,
    });

    const { loading, error, data, refetch } = useQuery<{ guideCategories: IGuideCategory[] }>(GUIDE_CATEGORIES, {
        variables: {
            applicationId: applicationId,
        },
        fetchPolicy: adminMode ? 'network-only' : 'cache-first',
        skip: state.search !== '',
    });

    const { loading: loadingUG, error: errorUG, data: dataUG } = useQuery(USER_GUIDES, {
        variables: {
            applicationId: applicationId,
            guideType: guideType,
            title: '%' + state.search + '%',
            page: 1,
            first: 100,
        },
        fetchPolicy: adminMode ? 'network-only' : 'cache-first',
        skip: state.search === '',
    });

    const guideTitle = useMemo(() => {
        let key = applicationId || 'all';
        return t('guide.' + key).toUpperCase();
    }, [applicationId, t]);

    useEffect(() => {
        if (locationState?.refetch) {
            refetch();
        }
        // eslint-disable-next-line
    }, [locationState?.refetch]);

    useEffect(() => {
        if (data?.guideCategories) {
            setState(prevState => ({ ...prevState, guideCategories: data.guideCategories }));
        }
        if (locationState?.applicationId) {
            setContext(prevContext => ({ ...prevContext, applicationId: locationState.applicationId }));
        }
        // eslint-disable-next-line
    }, [data, locationState?.applicationId]);

    return (
        <Layout>
            <Grid
                container
                alignItems="center"
                paddingX={2}
                paddingTop={3}
                paddingBottom={1}
            >
                <Grid
                    item xs={12} sm
                    textAlign="center"
                >
                    {guideTitle}
                </Grid>
            </Grid>
            {storedToken == null && (
                <Grid 
                    container
                    alignItems="center"
                    justifyContent="center"
                    paddingX={2}
                    paddingTop={2}
                    paddingBottom={1}
                >
                    <Grid
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        border="1px solid rgb(80, 80, 80)"
                        borderRadius={1}
                        padding={2}
                    >
                        <Grid
                            marginBottom={2}
                            textAlign="center"
                        >
                            {t('login_to_access')}
                        </Grid>
                        <Button
                            component={Link}
                            to="/login"
                            endIcon={<LoginIcon />}
                        >
                            {t('login')}
                        </Button>
                    </Grid>
                </Grid>
            )}
            <Grid padding={2}> 
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                >
                    {/* -------------------- SEARCH INPUT -------------------- */}
                    <Grid item xs={12} sm>
                        <SearchInput
                            value={state.searchValue}
                            onChange={searchValue => setState(prevState => ({ ...prevState, searchValue }))}
                            onSearch={search => setState(prevState => ({ ...prevState, search }))}
                            loading={loading}
                        />
                    </Grid>
                    {/* -------------------- START SEARCH -------------------- */}
                    <Grid item xs="auto">
                        <Tooltip title={t('start_search')}>
                            <Fab onClick={() => setState(prevState => ({ ...prevState, search: prevState.searchValue }))}>
                                <SearchIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                    {/* -------------------- CANCEL SEARCH -------------------- */}
                    <Grid item xs="auto">
                        <Tooltip title={t('cancel_research')}>
                            <Fab onClick={() => setState(prevState => ({ ...prevState, search: '', searchValue: '' }))}>
                                <SearchOffIcon />
                            </Fab>
                        </Tooltip>
                    </Grid>
                    {adminMode && (
                        <>
                            {/* -------------------- CREATE -------------------- */}
                            <Grid item xs="auto">
                                <Tooltip title={t('add_category')}>
                                    <Fab
                                        component={Link}
                                        to="/guide-categories/create"
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </Grid>
                            {/* -------------------- SORT -------------------- */}
                            {state.guideCategories.length > 1 && (
                                <Grid item xs="auto">
                                    <AnimatedBadge disabled={!state.sorting}>
                                        <Tooltip title={t('reorder')}>
                                            <Fab
                                                onClick={() => setState(prevState => ({ ...prevState, sorting: !prevState.sorting }))}
                                                color={state.sorting ? 'success' : 'default'}
                                            >
                                                <SortIcon />
                                            </Fab>
                                        </Tooltip>
                                    </AnimatedBadge>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- LIST -------------------- */}
            {state.search === '' ? (
                <GuideCategoryList
                    loading={loading}
                    sorting={state.sorting}
                    error={error}
                    guideCategories={state.guideCategories}
                    setGuideCategories={guideCategories => setState(prevState => ({ ...prevState, guideCategories }))}
                />
            ) : (
                <UserGuideList
                    loading={loadingUG}
                    error={errorUG}
                    userGuides={dataUG?.userGuides?.data}
                />
            )}
        </Layout>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- GUIDE CATEGORY LIST ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function GuideCategoryList({
    loading,
    sorting,
    error,
    guideCategories,
    setGuideCategories,
} : {
    loading: boolean,
    sorting: boolean,
    error: ApolloError | undefined,
    guideCategories: IGuideCategory[],
    setGuideCategories: (guideCategories: IGuideCategory[]) => void,
}) {

    const { adminMode } = useContext(MenuContext) as IMenuContext;
    const [reorderGuideCategories] = useMutation(REORDER_GUIDE_CATEGORIES);

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        document.body.style.cursor = 'default';
        let sortedCategories = arrayMoveImmutable(guideCategories, oldIndex, newIndex);
        setGuideCategories(sortedCategories.map((b, index) => ({ ...b, position: index + 1 })));
        reorderGuideCategories({ variables: { guideCategories: sortedCategories.map((c, index) => ({ id: c.id, position: index + 1 })) } });
    };

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            {(
                guideCategories
                && guideCategories.length > 0
            ) ? (
                <SortableList
                    onSortStart={() => document.body.style.cursor = 'grabbing'}
                    onSortEnd={onSortEnd}
                    axis="xy"
                >
                    <div>
                        {guideCategories.map((guideCategory, index) => (
                            <SortableItem
                                key={guideCategory.id}
                                index={index}
                                disabled={!sorting}
                            >
                                <div
                                    className="ListItemCard"
                                    style={{ cursor: sorting ? 'grab' : undefined }}
                                >
                                    <ListItemButton
                                        component={Link}
                                        to={'/guide-categories/' + guideCategory.id}
                                        disabled={sorting}
                                    >
                                        <div className="ListItemCard-label">
                                            <GuideCategoryIcon />
                                            <span>
                                                {capitalizeFirstLetter(guideCategory.name)}
                                            </span>
                                        </div>
                                        {(adminMode && guideCategory?.accessibleOffline) && (
                                            <AccessibleOffline
                                                containerProps={{ marginLeft: 8 }}
                                            />
                                        )}
                                        {guideCategory?.application && (
                                            <Application
                                                applicationName={guideCategory.application.name}
                                                type="pellet"
                                                containerProps={{ marginLeft: 8 }}
                                            />
                                        )}
                                    </ListItemButton>
                                </div>
                            </SortableItem>
                        ))}
                    </div>
                </SortableList>
            ) : (
                <NoResult />
            )}
        </>
    );
}
