import { gql } from '@apollo/client';

export const GUIDE_CATEGORIES = gql`
    query GuideCategories(
        $applicationId: ID
    ) {
        guideCategories(
            applicationId: $applicationId
        ) {
            id
            position
            name
            description
            iconUrl
            accessibleOffline
            application {
                id
                name
            }
            parentCategory {
                id
                position
                name
                description
                iconUrl
            }
        }
    }
`;

export const REORDER_GUIDE_CATEGORIES = gql`
    mutation ReorderGuideCategories($guideCategories: [GuideCategoryPositionInput]!) {
        reorderGuideCategories(input: $guideCategories)
    }
`;
