import React from 'react';

// CSS
import './title.css';

// Services
import { magentaMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- TITLE ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Title({
    children,
    required = false,
    titleProps,
} : {
    children: React.ReactNode,
    required?: boolean,
    titleProps?: React.CSSProperties,
}) {
    return (
        <label
            className="Title-label"
            style={{ ...titleProps }}
        >
            {children}
            {required && (
                <span
                    className="Title-required"
                    style={{ color: magentaMain }}
                >
                    *
                </span>
            )}
        </label>
    );
}
