import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// Apollo
import { useQuery } from '@apollo/client';
import { GUIDE_CATEGORIES } from 'views/guide-categories/gql-guide-categories';

// Services
import { IGuideCategory, ISelectOptionWithApp } from 'services/interfaces';

// Components
import Title from 'components/title';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------- SELECT GUIDE CATEGORY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function SelectGuideCategory({
    value,
    onChange,
    title,
    isDisabled = false,
    containerProps,
} : {
    value: ISelectOptionWithApp | null,
    onChange: (value: ISelectOptionWithApp | null) => void,
    title?: string,
    isDisabled?: boolean,
    containerProps?: React.CSSProperties,
}) {

    const { t } = useTranslation();
    const [options, setOptions] = useState<ISelectOptionWithApp[]>([]);

    const { loading, data } = useQuery<{ guideCategories: IGuideCategory[] }>(GUIDE_CATEGORIES, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (data?.guideCategories) {
            setOptions(data.guideCategories.map(c => ({ value: c.id, label: c.name, application: c.application })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div style={containerProps}>
            <Title>
                {title || t('category')}
            </Title>
            <Select
                placeholder={t('categories')}
                value={value}
                onChange={onChange}
                options={options}
                menuPlacement="auto"
                isClearable={true}
                isLoading={loading}
                isDisabled={isDisabled}
                styles={{ control: base => ({...base, marginTop: 4 }) }}
            />
        </div>
    );
}
