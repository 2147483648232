import { gql } from '@apollo/client';

export const USER_GUIDE = gql`
    query UserGuide($id: ID!) {
        userGuide(id: $id) {
            id
            position
            title
            description
            content
            videoUrl
            guideType
            translations {
                id
                title
                description
                content
                videoUrl
                locale
            }
            guideCategory {
                id
                name
                application {
                    id
                    name
                }
            }
            application {
                id
                name
            }
            roles {
                id
                name
            }
        }
    }
`;
