import { useTranslation } from 'react-i18next';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ NO RESULT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function NoResult() {

    const { t } = useTranslation();

    return (
        <div style={{ padding: 24, textAlign: 'center' }}>
            {t('none.1')} {t('result')}.
        </div>
    );
}
